import {Injectable} from '@angular/core';
import {
  BurningSchemaStepCtrl,
  DryingSchemaStepCtrl,
  MoldingSchemaStepCtrl,
  PostProcessingSchemaStepCtrl,
  ProductionSchemaCtrl,
  ProductionSchemaStepCtrl,
  ProductionSchemaTemplateCtrl,
} from '../../core/sdk/controllers-productionschema';
import {Observable} from 'rxjs';
import {
  BurningSchemaStep,
  DryingSchemaStep,
  MoldingSchemaStep,
  PostProcessingSchemaStep,
  ProductionSchema,
  ProductionSchemaStep,
  ProductionSchemaTemplate,
} from '../../core/sdk/model-productionschema';
import {Utility} from '../utilities/utility';
import {ProductType} from '../../core/sdk/model-producttype';
import {ProductionSchemaStepType} from '../../core/sdk/enums-types';
import {JsonScope} from '../../core/sdk/jsonScope';

@Injectable({
  providedIn: 'root'
})
export class ProductionSchemaService {

  constructor(private productionSchemaStepCtrl: ProductionSchemaStepCtrl,
              private productionSchemaCtrl: ProductionSchemaCtrl,
              private moldingSchemaStepCtrl: MoldingSchemaStepCtrl,
              private burningSchemaStepCtrl: BurningSchemaStepCtrl,
              private productionSchemaTemplateCtrl: ProductionSchemaTemplateCtrl,
              private dryingSchemaStepCtrl: DryingSchemaStepCtrl,
              private postProcessingSchemaStepCtrl: PostProcessingSchemaStepCtrl) {
  }

  // PRODUCTION_SCHEMA

  getEmptyProductionSchema(productType: ProductType): ProductionSchema {
    return {
      id: 'production-schema/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      isDeleted: false,
      deleted: false,
      productionSchemaStepList: [],
      productType,
    };
  }

  getProductionSchemaForProduct(productId: string): Observable<ProductionSchema> {
    return this.productionSchemaCtrl.getProductionSchemaForProductType(Utility.getObjectId(productId));
  }

  updateProductionSchema(productionSchema: ProductionSchema): Observable<ProductionSchema> {
    return this.productionSchemaCtrl.updateObject(productionSchema, new JsonScope(false, ['firing-schema-step', 'furnace-model',
      'mold-group', 'mold-instance', 'mold-type', 'product-type', 'workspace-group', 'firing-schema']));
  }

  createProductionSchema(productionSchema: ProductionSchema): Observable<ProductionSchema> {
    return this.productionSchemaCtrl.createObject(productionSchema);
  }

  // PRODUCTION_SCHEMA_STEP

  getEmptyProductionSchemaStep(): ProductionSchemaStep {
    return {
      name: '',
      directLaborRateCost: 0,
      energyCost: 0,
      fixedOverheadCost: 0,
      indirectLaborRateCost: 0,
      variableOverheadCost: 0,
      id: 'production-schema-step/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      stepDuration: Utility.convertSecondsToDurationObject(0),
      deleted: false,
      isDeleted: false,
      index: 0,
      description: '',
      workspaceGroup: null,
      type: ProductionSchemaStepType.PRODUCTION_SCHEMA_STEP,
      productionSchema: null
    };
  }

  getEmptyBurningSchemaStep(): BurningSchemaStep {
    return {
      directLaborRateCost: 0,
      energyCost: 0,
      fixedOverheadCost: 0,
      indirectLaborRateCost: 0,
      variableOverheadCost: 0,
      id: 'production-schema-step/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      stepDuration: Utility.convertSecondsToDurationObject(0),
      deleted: false,
      isDeleted: false,
      index: 0,
      description: '',
      workspaceGroup: null,
      type: ProductionSchemaStepType.BURNING_SCHEMA_STEP,
      name: null,
      firingSchema: null,
      productionSchema: null,
    };
  }

  getEmptyMoldingSchemaStep(): MoldingSchemaStep {
    return {
      name: '',
      directLaborRateCost: 0,
      energyCost: 0,
      fixedOverheadCost: 0,
      indirectLaborRateCost: 0,
      variableOverheadCost: 0,
      id: 'production-schema-step/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      stepDuration: Utility.convertSecondsToDurationObject(0),
      deleted: false,
      isDeleted: false,
      index: 0,
      description: '',
      workspaceGroup: null,
      type: ProductionSchemaStepType.MOLDING_SCHEMA_STEP,
      moldType: null,
      productionSchema: null,
    };
  }

  getEmptyDryingSchemaStep(): DryingSchemaStep {
    return {
      deleted: false,
      description: '',
      directLaborRateCost: 0,
      energyCost: 0,
      entityVersion: 0,
      fixedOverheadCost: 0,
      id: 'production-schema-step/' + Utility.getUUID(),
      index: 0,
      indirectLaborRateCost: 0,
      isDeleted: false,
      name: '',
      stepDuration: Utility.convertSecondsToDurationObject(0),
      timestamp: Date.now(),
      type: ProductionSchemaStepType.DRYING_SCHEMA_STEP,
      variableOverheadCost: 0,
      version: 0,
      weightReduction: 0,
      workspaceGroup: null,
      productionSchema: null,
    };
  }

  public getEmptyPostProcessingSchemaStep(): PostProcessingSchemaStep {
    return {
      name: '',
      directLaborRateCost: 0,
      energyCost: 0,
      fixedOverheadCost: 0,
      indirectLaborRateCost: 0,
      variableOverheadCost: 0,
      id: 'production-schema-step/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      stepDuration: Utility.convertSecondsToDurationObject(0),
      deleted: false,
      isDeleted: false,
      index: 0,
      description: '',
      quantityOfMiddleProductsAfterPostProcessing: 1,
      workspaceGroup: null,
      type: ProductionSchemaStepType.POST_PROCESSING_SCHEMA_STEP,
      productionSchema: null,
    };
  }

  updateProductionSchemaStep(productionSchema: ProductionSchemaStep): Observable<ProductionSchemaStep> {
    return this.productionSchemaStepCtrl.updateObject(productionSchema);
  }

  updateBurningSchemaStep(schemaStep: BurningSchemaStep): Observable<BurningSchemaStep> {
    return this.burningSchemaStepCtrl.updateObject(schemaStep);
  }

  updateMoldingSchemaStep(schemaStep: MoldingSchemaStep): Observable<MoldingSchemaStep> {
    return this.moldingSchemaStepCtrl.updateObject(schemaStep);
  }

  updateDryingSchemaStep(schemaStep: DryingSchemaStep): Observable<DryingSchemaStep> {
    return this.dryingSchemaStepCtrl.updateObject(schemaStep);
  }

  public updatePostProcessingSchemaStep(schemaStep: PostProcessingSchemaStep): Observable<PostProcessingSchemaStep> {
    return this.postProcessingSchemaStepCtrl.updateObject(schemaStep);
  }

  removeProductionSchemaStep(productionSchemaStepId: string): Observable<ProductionSchema> {
    return this.productionSchemaStepCtrl.deleteSingleProductionSchemaStep(Utility.getObjectId(productionSchemaStepId));
  }

  removeProductionSchemaStepFromList(stepList: string[]): Observable<void> {
    return this.productionSchemaStepCtrl.deleteProductionSchemaStepList(stepList);
  }

  // SCHEMA TEMPLATES

  getEmptyProductionSchemaTemplate(): ProductionSchemaTemplate {
    return {
      id: 'production-schema-template/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      productionSchemaStepList: [],
      name: '',
      description: '',
      deleted: false,
      isDeleted: false
    };
  }

  importProductionSchemaTemplate(templateId): Observable<ProductionSchemaStep[]> {
    return this.productionSchemaTemplateCtrl.getDuplicatedTemplateProductionStepList(Utility.getObjectId(templateId));
  }

  getAllProductionSchemaTemplatesId(): Observable<ProductionSchemaTemplate[]> {
    return this.productionSchemaTemplateCtrl.getAllTemplatesWithoutSteps();
  }

  getAllProductionSchemaTemplates(): Observable<ProductionSchemaTemplate[]> {
    return this.productionSchemaTemplateCtrl.getAllTemplates();
  }

  updateProductionSchemaTemplate(template: ProductionSchemaTemplate): Observable<ProductionSchemaTemplate> {
    return this.productionSchemaTemplateCtrl.updateObject(template, new JsonScope(false, ['firing-schema-step',
      'furnace-model', 'production-schema', 'firing-schema', 'mold-type', 'workspace-group']));
  }

  removeProductionSchemaTemplate(templateId: string): Observable<void> {
    return this.productionSchemaTemplateCtrl.deleteObject(Utility.getObjectId(templateId));
  }

  createProductionSchemaTemplate(template: ProductionSchemaTemplate): Observable<ProductionSchemaTemplate> {
    return this.productionSchemaTemplateCtrl.createObject(template);
  }

  getCloneOfProductionSchemaTemplate(templateId: string): Observable<ProductionSchemaTemplate> {
    return this.productionSchemaTemplateCtrl.createDuplicateFromProductionSchemaTemplate(Utility.getObjectId(templateId));
  }

}
