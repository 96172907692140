import {Injectable} from '@angular/core';
import {CriteriaValue, CriteriaValueType, SearchCriteria, SearchCriteriaForClass} from './sdk/model-dto';

@Injectable({
  providedIn: 'root'
})
export class SearchCriteriaService {
  constructor() {
  }

  updateSearchCriteria(searchCriteriaForClass: SearchCriteriaForClass,
                       filteredFieldName: string,
                       inClassPropertyName: string | undefined,
                       propertyClass: string | undefined,
                       value: CriteriaValue,
                       operationSign: string,
                       multipleCriteriaOnFieldAllowed: boolean,
                       deepFilterNestingList?: string[] | undefined,
  ): SearchCriteriaForClass {
    if (((this.getSearchCriteriaForField(filteredFieldName, searchCriteriaForClass) === null
        || this.getSearchCriteriaForField(filteredFieldName, searchCriteriaForClass) === undefined) || multipleCriteriaOnFieldAllowed)
      && !this.isBlank(value)) {
      searchCriteriaForClass.searchCriteriaList.push(this.createSearchCriteriaForField(
        filteredFieldName, inClassPropertyName, propertyClass, deepFilterNestingList, operationSign, value));
    } else {
      const searchForField: any = this.getSearchCriteriaForField(filteredFieldName, searchCriteriaForClass);
      if (searchForField !== undefined) {
        const searchCriteria: SearchCriteria = searchForField;
        const index = searchCriteriaForClass.searchCriteriaList.indexOf(searchCriteria);
        if (this.isBlank(value)) {
          searchCriteriaForClass.searchCriteriaList.splice(index, 1);
        } else {
          searchCriteria.criteriaMap[operationSign] = value;
          searchCriteriaForClass.searchCriteriaList[index] = searchCriteria;
        }
      }
    }
    return searchCriteriaForClass;
  }

  private isBlank(value: CriteriaValue): boolean {
    return value.value === '' || (value.value === 0 && value.valueType !== CriteriaValueType.ENUM);
  }

  public createSearchCriteriaForClass(className: string, sortBy: string, ascending: boolean,
                                      sortByInClassPropertyName?: string): SearchCriteriaForClass {
    return {
      deepSortNestingList: undefined,
      isSortingStringNumeric: false,
      sortPropertyClass: undefined,
      sortingStringNumeric: undefined,
      searchCriteriaList: [],
      filteredClass: className,
      sortBy,
      sortByInClassPropertyName,
      ascending
    };
  }

  public getSearchCriteriaForField(column: string, searchCriteriaForClass: SearchCriteriaForClass): SearchCriteria | undefined {
    return searchCriteriaForClass.searchCriteriaList.find(criteria => criteria.filterFieldName === column);
  }

  public createSearchCriteriaForField(filterFieldName: string, inClassPropertyName: string, propertyClass: string,
                                      deepFilterNestingList: string[], sign: string, value: CriteriaValue): SearchCriteria {
    return {
      deepFilterNestingList,
      filterFieldName,
      inClassPropertyName,
      propertyClass,
      criteriaMap: {[sign]: value}
    };
  }

  public removeAllSearchCriteriaForGivenColumn(searchCriteriaForClass: SearchCriteriaForClass, column: string): SearchCriteriaForClass {
    if (this.searchCriteriaForFieldExists(searchCriteriaForClass, column)) {
      searchCriteriaForClass = this.removeSearchCriteriaForGivenColumn(searchCriteriaForClass, column);
      this.removeAllSearchCriteriaForGivenColumn(searchCriteriaForClass, column);
    }
    return searchCriteriaForClass;
  }


  public searchCriteriaForFieldExists(searchCriteriaForClass: SearchCriteriaForClass, column: string): boolean {
    return this.getSearchCriteriaForField(column, searchCriteriaForClass) !== null
      && this.getSearchCriteriaForField(column, searchCriteriaForClass) !== undefined;
  }

  public updateFilteredClassNameInSearchCriteria(searchCriteriaForClass: SearchCriteriaForClass, newClassName: string)
    : SearchCriteriaForClass {
    searchCriteriaForClass.filteredClass = newClassName;
    return searchCriteriaForClass;
  }

  public removeSearchCriteriaForGivenColumn(searchCriteriaForClass: SearchCriteriaForClass, column: string): SearchCriteriaForClass {
    const searchForField: any = this.getSearchCriteriaForField(column, searchCriteriaForClass);
    const index = searchCriteriaForClass.searchCriteriaList.indexOf(searchForField);
    searchCriteriaForClass.searchCriteriaList.splice(index, 1);
    return searchCriteriaForClass;
  }

}
