<div class="template-schemas">

  <button
    class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content global-align--self-end"
    type="button"
    (click)="createNewTemplate()">{{'schemas.create-new-template' | translate}}</button>

  <ng-container *ngFor="let template of data">
    <app-schema-template-overview-tile (removeTemplateEmitter)="removeSchemaTemplate($event)"
                                       (cloneTemplateEmitter)="cloneTemplate($event)"
                                       (editTemplateEmitter)="editTemplate($event)" [template]="template">
    </app-schema-template-overview-tile>
  </ng-container>
</div>

