<div class="mobile-inventory">
  <h3>{{'mobile.counted-quantity' | translate}}</h3>
    <form
      class="mobile-inventory__form"
      [formGroup]="formGroup">
      <input
        autofocus
        type="number"
        formControlName="countedQuantity"
        (keyup.enter)="onSubmit()">
      <span
        [ngClass]="countedQuantityControl.invalid && (countedQuantityControl.dirty || countedQuantityControl.touched) ? 'visibility--true' : 'visibility--false'"
        class="global-error-message global-align--self-center"
      >{{'common.valid-value-required' | translate}}</span>

      <div class="mobile-inventory__form__buttons-container">
        <button
          type="submit"
          [disabled]="formGroup.invalid"
          (click)="onSubmit()">{{'mobile.declare' | translate}}</button>
        <button
          *ngIf="isScanAreMode"
          type="submit"
          class="global-margin--left-5vw"
          (click)="onReturn()">{{'common.return' | translate}}</button>
      </div>
    </form>
</div>
