<p-table
  class="main-table-header"
  responsiveLayout="scroll"
  dataKey="id"
  [value]="getTableData()"
  (onPage)="paginate($event)"
  [paginator]="!shouldExportBeApplied"
  [lazy]="true"
  [rowHover]="true"
  [(first)]="pageable.pageNumber"
  [rows]="pageable.pageSize"
  [totalRecords]="pageable.count">

  <ng-template pTemplate="header">
    <tr>
      <th (click)="sortByColumn('name')"
          pSortableColumn="name">{{'common.name' | translate}}
        <p-sortIcon class="no-print" field="name"></p-sortIcon>
      </th>
      <th (click)="sortByColumn('shortname')"
          pSortableColumn="shortname">{{'common.shortname' | translate}}
        <p-sortIcon class="no-print" field="status"></p-sortIcon>
      </th>
      <th (click)="sortByColumn('pricePerUnitInEUR', false, 'activeCurrencyRate', 'CurrencyRate')"
          pSortableColumn="activeCurrencyRate">{{'common.price-per-unit-in-eur' | translate}}
        <p-sortIcon class="no-print" field="status"></p-sortIcon>
      </th>
      <th (click)="sortByColumn('timestamp')"
          pSortableColumn="timestamp">{{'common.last-rate-update-date' | translate}}
        <p-sortIcon class="no-print" field="status"></p-sortIcon>
      </th>
      <th>{{'common.action' | translate}}</th>
    </tr>

    <tr
      class="primeng-table__search-row no-print">
      <td>
        <input
          appStringLengthInputValidation
          type="text"
          class="global-input primeng-table__filter-col"
          placeholder="{{ 'common.search-by-currency-name' | translate }}"
          [(ngModel)]="currencyName"
          (keydown.enter)="searchByString($event, 'name')">
      </td>
      <td>
        <input
          appStringLengthInputValidation
          type="text"
          class="global-input primeng-table__filter-col"
          placeholder="{{ 'common.search-by-shortname' | translate }}"
          [(ngModel)]="currencyShortname"
          (keydown.enter)="searchByString($event, 'shortname')">
      </td>
      <td>
        <input
          appIntegerInputValidation
          type="number"
          class="global-input primeng-table__filter-col"
          placeholder="{{ 'common.search-by-price-per-unit' | translate }}"
          [min]="0"
          [(ngModel)]="currencyPricePerUnitInEur"
          (keydown.enter)="searchByNumber($event, 'pricePerUnitInEUR', 'activeCurrencyRate', 'CurrencyRate')">
      </td>
      <td>
        <p-calendar
          dateFormat="yy-mm-dd"
          class="primeng-table__calendar-filter-col  main-calendar"
          placeholder="{{ 'common.search-by-last-rate-update-date' | translate }}"
          (onSelect)="searchByDate($event, 'timestamp')"
          [showButtonBar]="true"
          [readonlyInput]="true"
          [showIcon]="true"
          [clearButtonStyleClass]="'display--false'"
          [showOnFocus]="false"
          [inputStyleClass]="'global-input'"
          [styleClass]="'global-width--100'"
          [(ngModel)]="dateFilterField">
        </p-calendar>
      </td>
      <td>
        <button
          class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content global-align--self-end"
          data-cy="reset-filters-button"
          (click)="resetFilters()"
        >{{'common.reset-filters' | translate}}
        </button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">
        {{'common.no-records-available' | translate}}
      </td>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-currency>
    <tr
      [pRowToggler]="currency"
      [pEditableRow]="currency"
      class="global-pointer">
      <td>{{currency.name}}</td>
      <td>{{currency.shortname}}</td>
      <td>{{currency.activeCurrencyRate?.pricePerUnitInEUR}}</td>
      <td>{{convertTimestampToStringData(currency.activeCurrencyRate?.timestamp) | translate}}</td>
      <td
        class="global-text-align__center">
        <button
          pSaveEditableRow
          type="button"
          class="global-background-color--green global-width--fit-content  global-margin__small-right
         button button__submit-button button__submit-button--color"
          data-cy="assign-button"
          (click)="emitAssignedCurrency(currency)"
        >{{'common.assign' | translate}}</button>
      </td>
    </tr>
  </ng-template>

</p-table>
