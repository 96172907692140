<ng-template appPlaceholderUserModalDirective></ng-template>

<div class="full-schema-edition"
     data-cy="full-schema-edition-div">
  <div class="full-schema-edition__current-schema schemas__list__record global-border-radius">
    <div
      class="full-schema-edition__current-schema__information global-flex global-flex-direction__row"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit()">

      <div
        class="full-schema-edition__current-schema__information__read-only global-flex global-flex-direction__column global-width--50">
        <label class="global-subtitle">{{'schemas.summary-steps-duration' | translate}}</label>
        <label>{{transformDurationToString()}}</label>
      </div>

      <div class="full-schema-edition__current-schema__information__import global-width--50 global-padding--02-top global-padding--02-bottom">
        <div
          *ngIf="shouldNameBeDisplayed"
          class="template-schemas__form__general-panel__column">
          <label class="global-subtitle">
            {{'schemas.name' | translate}}
          </label>
          <input
            id="schema-name"
            class="global-input"
            formControlName="name"
            [ngClass]="nameControl.invalid ? 'global-invalid-input-box-shadow' : ''">
        </div>

        <div
          *ngIf="shouldDescriptionBeDisplayed"
          class="template-schemas__form__general-panel__column">
          <label class="global-subtitle">{{'schemas.template-description' | translate}}</label>
          <textarea
            class="global-input global-height--70"
            formControlName="description"
            [ngClass]="descriptionControl.invalid ? 'global-invalid-input-box-shadow' : ''"
          ></textarea>
        </div>

      </div>
      <div class="full-schema-edition__current-schema__information__import global-width--50">


        <ng-container *ngIf="importTemplateFlag; then importTemplate; else importButton"></ng-container>

        <ng-template
          #importTemplate
          class="global-flex global-flex-direction__column">
          <div class="global-flex global-width--80"></div>
          <label class="global-subtitle">
            {{'schemas.select-schema-template-to-import' | translate}}
          </label>
          <p-dropdown
            *ngIf="templatesOptions"
            class="global-input global-width--fit-content"
            data-cy="import-schema-dropdown"
            [options]="templatesOptions"
            [autoDisplayFirst]="true"
            [optionLabel]="'name'"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="selectedTemplate"
          ></p-dropdown>
          <div class="global-flex global-flex-direction__row">
            <button
              type="button"
              class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
              data-cy="import-button"
              (click)="importSelectedTemplate(selectedTemplate.id)"
            >{{'common.import' | translate}}</button>
            <button
              type="button"
              class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
              data-cy="cancel-button"
              (click)="toggleImportFlag()"
            >{{'common.cancel' | translate}}
            </button>
          </div>
        </ng-template>

        <ng-template #importButton>
          <button
            type="button"
            class="button button__submit-button button__submit-button--color button__submit-button--background-color full-schema-edition__current-production-schema__information__import__import-button"
            data-cy="import-schema-template-button"
            (click)="toggleImportFlag()"
          >{{'schemas.import-schema-template-for-current-schema' | translate}}</button>
        </ng-template>
      </div>
    </div>
    <div class="full-schema-edition__current-schema__action global-flex global-flex-direction__column">
      <button
        type="submit"
        class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
        data-cy="submit-button"
        [disabled]="formGroup.invalid"
        (click)="onSubmit()"
      >{{'common.submit' | translate}}</button>
      <!--        [disabled]="!isFormValid()"-->
      <button
        type="button"
        class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
        data-cy="close-button"
        (click)="returnPreviousLocation()"
      >{{'common.close' | translate}}</button>
    </div>
  </div>


  <div class="full-schema-edition__header global-flex global-flex-direction__column global-justify__center">
    <h2 class="base-display__title">
      {{'schemas.full-schema-edit-mode' | translate}}
    </h2>
    <div class="full-schema-edition__header__buttons global-flex global-flex-direction__row global-justify__flex-end">

      <button
        type="button"
        class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
        data-cy="clear-all-steps"
        (click)="clearAllStep()"
      >{{'schemas.clear-all-steps' | translate}}</button>
      <button
        type="button"
        class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
        data-cy="create-new-step"
        (click)="createNewStep()"
      >{{'schemas.add-new-step' | translate}}</button>
    </div>
  </div>

  <div class="full-schema-edition__list">
    <div
      *ngIf="currentSchema"
      class="full-schema-edition__list__record">
      <app-schema-step-list
        #schemaStepsComponent
        [currentSchema]="currentSchema"
        [closeAllStepEditionEmitter]="closeAllStepEditionEmitter"
        [indexColumnApplied]="true">
      </app-schema-step-list>
    </div>
  </div>


  <div
    *ngIf="showPlaceHolder()"
    class="production-schema__placeholder global-margin__small">
    <h3>{{'schemas.there-is-no-defined-steps' | translate}}</h3>
  </div>

</div>

