/* tslint:disable  max-line-length  align  eofline  no-trailing-whitespace  typedef-whitespace  whitespace   */
import {Functionality, Person} from './bighero-model';
import {WebsocketMessageType} from './enums-types';
import {Price} from './model-currency';
import {Offer} from './model-offer';
import {Order} from './model-order';
import {Language} from './model-translations';
import {FunctionalityGroup} from './model-enums';

export interface CriteriaValue {
  value: any | null;
  valueType: CriteriaValueType | null;
}

export enum CriteriaValueType {
STRING,
NUMBER,
DATE,
ENUM,
RANGE
}

export interface DeclarationsSummaryDetails {
  summaryFaultyProductsQuantity: number | null;
  summaryGoodProductsQuantity: number | null;
}

export interface ErrorMessage {
  message: string | null;
}

export interface NewPassword {
  password: string | null;
  token: string | null;
}

export interface OfferDetails {
  anyDummyProductInTheOfferProductList: boolean | null;
  isAnyDummyProductInTheOfferProductList: boolean | null;
  offer: Offer | null;
  totalManufacturingCost: number | null;
  totalPrice: Price | null;
}

export interface OrderInProduction {
  order: Order | null;
  productsInProduction: number | null;
  readyProducts: number | null;
}

export interface OrderProgressDetails {
  summaryOrderedProductsQuantity: number | null;
  summaryProducedProductsQuantity: number | null;
}

export interface PasswordChange {
  email: string | null;
  newPassword: string | null;
  oldPassword: string | null;
}

export interface PersonLogin {
  email: string | null;
  password: string | null;
}

export interface PersonLoginInfo {
  defaultUserLanguage: Language | null;
  functionalityMap: {[key: string] :Functionality[]} | {[key: string] :any} | null;
  mainFunctionalities: FunctionalityGroup[] | any[] | null;
  person: Person | null;
  token: string | null;
}

export interface ProductionHomeOverview {
  freeDryers: number | null;
  freeMachines: number | null;
  freeOvens: number | null;
  notBookedPositions: number | null;
  orderReadyForProduction: number | null;
  ordersInProduction: number | null;
}

export interface SearchCriteria {
  criteriaMap: {[key: string] :CriteriaValue} | null;
  deepFilterNestingList: string[] | null;
  filterFieldName: string | null;
  inClassPropertyName: string | null;
  propertyClass: string | null;
}

export interface SearchCriteriaForClass {
  ascending: boolean | null;
  deepSortNestingList: string[] | null;
  filteredClass: string | null;
  isSortingStringNumeric: boolean | null;
  searchCriteriaList: SearchCriteria[] | null;
  sortBy: string | null;
  sortByInClassPropertyName: string | null;
  sortPropertyClass: string | null;
readonly   sortingStringNumeric: boolean | null;
}

export interface SearchCriteriaValuesRange {
  endValue: any | null;
  startValue: any | null;
}

export interface SupervisionNotification {
  messageType: WebsocketMessageType | null;
  notificationCounter: number | null;
  workerName: string | null;
}

export interface TableDataJoiner {
  joinedDataObject: any | null;
  tableDataRootObject: any | null;
}

export interface Version {
  artifact: string | null;
  buildTime: string | null;
  group: string | null;
  name: string | null;
  version: string | null;
}

export interface Violation {
  errorUIkey: string | null;
  param: undefined | ViolationParam[];
  paramList: ViolationParam[] | null;
}

export interface ViolationParam {
  name: string | null;
  value: any | null;
}

export interface WebsocketMessage {
  messageType: WebsocketMessageType | null;
  messageValue: any | null;
}

