import {Component, Input} from '@angular/core';
import {CrossFunctionalService} from '../../services/cross-functional.service';
import {PTableControlService} from '../../services/p-table-control.service';

@Component({
  selector: 'app-missing-data-placeholder',
  templateUrl: './missing-data-placeholder.component.html',
  styles: []
})
export class MissingDataPlaceholderComponent {

  @Input() showReturnButton = false;

  constructor(private crossFunctionalService: CrossFunctionalService,
              private pTableControlService: PTableControlService) {
  }

  returnToPreviousFunctionality(): void {

  }

}
