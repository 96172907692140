<ng-container *ngIf="!createCountryFlag then countryTable else createCountry"></ng-container>

<ng-template #countryTable>

  <div class="country-table">
    <div class="country-table__buttons">
      <button
        *ngIf="!isBasketModeActive() && !assignCountryFlag"
        class="global-button"
        data-cy="turn-basket-one-button"
        (click)="turnBasketOn()"
      >{{'common.turn-basket-on' | translate}}</button>
      <button
        *ngIf="!assignCountryFlag && !isBasketModeActive()"
        type="button"
        class="common-button"
        (click)="toggleCreateCountryFlag()"
      >{{ translateModule + 'create-country' | translate}}</button>
      <button
        *ngIf="assignCountryFlag"
        type="button"
        class="common-button"
        (click)="returnEmitter.emit()"
      >{{'common.return' | translate}}</button>
    </div>

    <ng-container
      *ngIf="isBasketModeActive()">
      <app-basket
        class="no-print"
        [tableName]="tableName"
        [objectsList]="getCountriesInBasket()"
        [tableHeaderTemplate]="countriesBasketTableHeader"
        [tableContentTemplate]="countriesBasketTableContent"
        (basketOffEmitter)="turnBasketOff()">
      </app-basket>
    </ng-container>

    <ng-template #countriesBasketTableHeader>
      <th>{{'admin.name' | translate}}</th>
      <th>{{'admin.is-inside-european-union' |translate}}</th>
      <th>{{'admin.is-outside-europe-continent' |translate}}</th>
    </ng-template>

    <ng-template #countriesBasketTableContent let-basketContentObject>
      <td>{{basketContentObject.name}}</td>
      <td>{{'admin.' + basketContentObject.isInsideEuropeanUnion | translate}}</td>
      <td>{{'admin.' + basketContentObject.isOutsideEuropeContinent | translate}}</td>
    </ng-template>

    <p-table
      #pTable
      class="main-table-header"
      responsiveLayout="scroll"
      dataKey="id"
      selectionMode="single"
      [value]="getTableData()"
      [rowExpandMode]="isBasketModeActive() ? 'multi' : 'single'"
      [paginator]="!shouldExportBeApplied"
      [lazy]="true"
      [rowHover]="true"
      [rows]="pageable.pageSize"
      [totalRecords]="pageable.count"
      (onPage)="paginate($event)"
      [(first)]="pageable.first"
      (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowSelect($event)">>
      <ng-template pTemplate="header">
        <tr>
          <th
            *ngIf="!assignCountryFlag"
            class="primeng-table__search-col no-print"></th>
          <th (click)="sortByColumn('name')"
              pSortableColumn="name">{{ translateModule + 'name' | translate}}
            <p-sortIcon class="no-print" field="name"></p-sortIcon>
          </th>
          <th (click)="sortByColumn('isInsideEuropeanUnion')"
              pSortableColumn="isInsideEuropeanUnion">{{ translateModule + 'is-inside-european-union' | translate}}
            <p-sortIcon class="no-print" field="isInsideEuropeanUnion"></p-sortIcon>
          </th>
          <th (click)="sortByColumn('isOutsideEuropeContinent')"
              pSortableColumn="isOutsideEuropeContinent">{{translateModule + 'is-outside-europe-continent' | translate}}
            <p-sortIcon class="no-print" field="isOutsideEuropeContinent"></p-sortIcon>
          </th>
          <th *ngIf="assignCountryFlag">{{'common.action' | translate}}</th>
        </tr>
        <tr
          *ngIf="showFilteringRow"
          class="primeng-table__search-row no-print">
          <th
            *ngIf="!assignCountryFlag"
            class="primeng-table__search-col"><i class="primeng-table__search-icon"></i></th>
          <td>
            <input
              appStringLengthInputValidation
              type="text"
              class="global-input primeng-table__filter-col"
              placeholder="{{ 'common.search-by-country-name' | translate }}"
              (keydown.enter)="searchByString($event, 'name')">
          </td>
          <td></td>
          <td></td>
          <td *ngIf="assignCountryFlag"></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="4">
            {{'common.no-records-available' | translate}}
          </td>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-country
        let-expanded="expanded">
        <tr [pRowToggler]="country"
            [pEditableRow]="country"
            [pSelectableRow]="country"
            (click)="clearTabToBeActivated()"
            class="global-pointer">
          <td
            *ngIf="!assignCountryFlag"
            class="no-print">
            <i class="primeng-table__angle-down"
               [ngClass]="expanded ? 'pi pi-angle-down' : 'pi pi-angle-right'"></i>
          </td>
          <td>{{country.name}}</td>
          <td>{{translateModule + country.isInsideEuropeanUnion | translate}}</td>
          <td>{{translateModule + country.isOutsideEuropeContinent | translate}}</td>
          <td *ngIf="assignCountryFlag">
            <button
              type="button"
              class="common-button"
              (click)="assignCountry(country)"
            >{{ translateModule + 'assign' | translate}}</button>
          </td>
        </tr>
      </ng-template>
      <ng-template
        *ngIf="!assignCountryFlag"
        pTemplate="rowexpansion"
        let-country>
        <tr *ngIf="!isBasketModeActive()">
          <td
            class="primeng-table__edit--tab-padding primeng-table__edit--tab--border-top global-background-color--background"
            colspan="4">
            <div class="primeng-table__edit--tab-component">
              <app-tabs
                [tabsMap]="getTabsMap(country)"
                [defaultActiveTab]="tabToBeActivate">
                <app-tab
                  [tabTitle]="country.name"
                  [active]="true"
                  [dataBindMap]="bindData(country)">
                </app-tab>
              </app-tabs>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-template>

<ng-template #createCountry>
  <div class="country-form-container">
    <h3 class="country-form-container__title-white-color">{{ translateModule + 'create-new-country' | translate}}</h3>
    <app-country-form
      (formSubmitEmitter)="reloadDataAfterCreateCountry()"
      (formCancelEmitter)="toggleCreateCountryFlag()">
    </app-country-form>
  </div>
</ng-template>





