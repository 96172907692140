import {Injectable} from '@angular/core';
import {Inventory, InventoryDeclaration, InventoryInProductionProduct, InventoryStoredProduct} from '../../../core/sdk/model-inventory';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {Utility} from '../../utilities/utility';
import {
  InventoryCtrl,
  InventoryDeclarationCtrl,
  InventoryInProductionProductCtrl,
  InventoryStoredProductCtrl
} from '../../../core/sdk/controllers-inventory';
import {InventoryReportFile} from '../../../core/sdk/model-files';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  private inventoryData: Inventory[];

  constructor(private inventoryCtrl: InventoryCtrl,
              private inventoryInProductionProductCtrl: InventoryInProductionProductCtrl,
              private inventoryStoredProductCtrl: InventoryStoredProductCtrl,
              private inventoryDeclarationCtrl: InventoryDeclarationCtrl) {
  }


  /// INVENTORY

  public setInventoryData(data: Inventory[]): void {
    this.inventoryData = data;
  }

  public getInventoryData(): Inventory[] {
    return this.inventoryData;
  }

  public getInventory(objectId: string): Observable<Inventory> {
    return this.inventoryCtrl.getObject(Utility.getObjectId(objectId));
  }

  public createInventory(countingStartDate: number): Observable<Inventory> {
    return this.inventoryCtrl.createInventory(countingStartDate);
  }

  public countInventories(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.inventoryCtrl.countInventories(searchCriteriaForClass);
  }

  public getInventories(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Inventory[]> {
    return this.inventoryCtrl.getInventories(searchCriteriaForClass, pageNumber, pageSize);
  }

  public generateInventoryReportDocumentFile(inventoryId: string): Observable<InventoryReportFile> {
    return this.inventoryCtrl.generateInventoryReportDocumentFile(Utility.getObjectId(inventoryId));
  }

  /// INVENTORY_IN_PRODUCTION_PRODUCTS

  public countInventoryInProductionProducts(searchCriteriaForClass: SearchCriteriaForClass, inventoryId: string): Observable<number> {
    return this.inventoryInProductionProductCtrl.countInventoryInProductionProducts(searchCriteriaForClass,
      Utility.getObjectId(inventoryId));
  }

  public getInventoryInProductionProducts(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number,
                                          inventoryId: string): Observable<InventoryInProductionProduct[]> {
    return this.inventoryInProductionProductCtrl.getInventoryInProductionProducts(searchCriteriaForClass,
      Utility.getObjectId(inventoryId), pageNumber, pageSize);
  }

  /// INVENTORY_STORED_PRODUCTS

  public countInventoryStoredProducts(searchCriteriaForClass: SearchCriteriaForClass, inventoryId: string): Observable<number> {
    return this.inventoryStoredProductCtrl.countInventoryStoredProducts(searchCriteriaForClass, Utility.getObjectId(inventoryId));
  }


  public getInventoryStoredProducts(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number,
                                    inventoryId: string): Observable<InventoryStoredProduct[]> {
    return this.inventoryStoredProductCtrl.getInventoryStoredProducts(searchCriteriaForClass, Utility.getObjectId(inventoryId),
      pageNumber, pageSize);
  }

  public getInventoryStoredProductsByStorageAreaBarcode(barcode: string): Observable<InventoryStoredProduct[]> {
    return this.inventoryStoredProductCtrl.getInventoryStoredProductsByStorageAreaBarcode(barcode);
  }

  public getInventoryStoredProductByBarcode(barcode: string): Observable<InventoryStoredProduct> {
    return this.inventoryStoredProductCtrl.getInventoryStoredProductByBarcode(barcode);
  }


  /// DECLARATIONS

  public countInventoryDeclarationForInventoryCountableStack(searchCriteriaForClass: SearchCriteriaForClass,
                                                             inventoryCountableStackId: string): Observable<number> {
    return this.inventoryDeclarationCtrl.countInventoryDeclarations(searchCriteriaForClass, Utility.getObjectId(inventoryCountableStackId));
  }

  public getInventoryDeclarationForInventoryCountableStack(searchCriteriaForClass: SearchCriteriaForClass,
                                                           pageNumber: number,
                                                           pageSize: number,
                                                           inventoryCountableStackId: string): Observable<InventoryDeclaration[]> {
    return this.inventoryDeclarationCtrl.getInventoryDeclarations(searchCriteriaForClass, Utility.getObjectId(inventoryCountableStackId),
      pageNumber, pageSize);
  }

  public createInventoryDeclaration(inventoryCountableStackId: string, countedValue: number): Observable<InventoryDeclaration> {
    return this.inventoryDeclarationCtrl.createInventoryDeclaration(Utility.getObjectId(inventoryCountableStackId), countedValue);
  }

}
