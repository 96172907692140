import {ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef} from '@angular/core';
import {MessageService} from 'primeng/api';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '../../core/translations/translate.service';
import {NotificationMessageType} from '../enums/NotificationMessageType';
import {SpinnerService} from './spinner.service';
import {UserDecisionModalService} from './user-decision-modal.service';
import {Violation} from '../../core/sdk/model-dto';
import {NotificationModalComponent} from '../base-components/notification-modal/notification-modal.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private viewContainer: ViewContainerRef;
  public componentRef: ComponentRef<any>;

  constructor(private messageService: MessageService,
              private translateService: TranslateService,
              private userDecisionModalService: UserDecisionModalService,
              private componentFactoryResolver: ComponentFactoryResolver) {
  }

  public displayNotificationToast(message: string, notificationMessageType: NotificationMessageType,
                                  notTranslatableAdditionalInformation?: string): void {

    let translatableToastMessage = this.translateService.translate(message);
    if (notTranslatableAdditionalInformation && !notTranslatableAdditionalInformation.isEmpty()) {
      translatableToastMessage += '\t' + notTranslatableAdditionalInformation + '\t';
    }
    this.messageService.add({
      severity: notificationMessageType,
      detail: translatableToastMessage
    });
  }

  public displayNotificationModal(notificationModalList: Violation[]): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(NotificationModalComponent);
    this.componentRef = this.viewContainer.createComponent(factory);
    this.componentRef.instance.notifications = notificationModalList;
  }

  public set viewContainerRef(vc: ViewContainerRef) {
    this.viewContainer = vc;
  }

  public closeNotificationModal(): void {
    this.viewContainer.clear();
  }

  public handleErrorResponseWithMessage(error: HttpErrorResponse, spinnerService: SpinnerService): void {
    spinnerService.deactivateSpinner();
    if (error.error?.message) {
      this.displayNotificationToast(error.error.message, NotificationMessageType.ERROR);
    } else {
      this.handleViolationError(error);
    }
  }

  private handleViolationError(error: HttpErrorResponse): void {
    const notificationModalList: Violation[] = [];
    const notificationToastList: Violation[] = [];
    const parsedViolationList = JSON.parse(error.error);
    if (parsedViolationList.length > 0) {
      parsedViolationList.forEach(violation => {
        if (violation.paramList.length > 0) {
          notificationModalList.push(violation);
        } else {
          notificationToastList.push(violation);
        }
      });
      if (notificationModalList.length > 0) {
        this.displayNotificationModal(notificationModalList);
      }
      if (notificationToastList.length > 0) {
        notificationToastList.forEach(violationToast => {
          this.displayNotificationToast(this.displayErrorNotificationMessage(violationToast), NotificationMessageType.ERROR);
        });
      }
    }
  }

  private displayErrorNotificationMessage(violation: Violation): string {
    return violation.errorUIkey ? violation.errorUIkey : 'common.no-error-info';
  }

}
