import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IgnoredKeyboardEventsService {

  public static shouldKeyboardEventBeIgnored(e: KeyboardEvent): boolean {
    return (e.key === 'ArrowRight') || (e.key === 'ArrowLeft') ||
    (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
    (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
    (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
    (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
    (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
    (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
    (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
    (e.key === 'x' && e.metaKey === true) || // Cmd+X (Mac)
    (e.key === 'Delete') ||
    (e.key === 'Backspace');
  }

}
