import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {BasketService} from '../services/basket.service';
import {Router} from '@angular/router';
import {ProductStoredInstance} from '../../core/sdk/model-warehouse';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html'
})
export class BasketComponent implements OnInit {

  @Input() public objectsList: any[];
  @Input() public tableName: string;
  @Input() public tableHeaderTemplate: TemplateRef<any>;
  @Input() public tableContentTemplate: TemplateRef<any>;
  @Output() public basketOffEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() public pickUpProductsCreateEmitter: EventEmitter<void> = new EventEmitter<void>();

  public showAllObjectsFlag = false;
  public isMaterialType: boolean;

  constructor(private basketService: BasketService,
              private router: Router) {
  }

  public ngOnInit(): void {
    this.isMaterialType = this.router.url.includes('raw-materials');
  }

  public turnBasketOff(): void {
    this.basketOffEmitter.emit();
  }

  public toggleShowAllObjectsFlag(): void {
    this.showAllObjectsFlag = !this.showAllObjectsFlag;
  }

  public getCorrectColSpanValue(): number {
    return this.isMaterialType ? 2 : this.tableName.includes('productType') ? 3 : 4;
  }


  public removeObject(object: any): void {
    for (const index in this.objectsList) {
      if (this.objectsList[index].id === object.id) {
        this.objectsList.splice(Number(index), 1);
        this.basketService.removeFromBasket(this.tableName, object);
        break;
      }
    }
  }

  public createPickUpProducts(): void {
    this.pickUpProductsCreateEmitter.emit();
  }

  private getProductStoredInstanceFromBasket(): ProductStoredInstance[] {
    return this.getProductStoredInstances() ?
      Array.from(
        this.getProductStoredInstances() as Set<ProductStoredInstance>) : [];
  }

  private getProductStoredInstances(): Set<object> {
    return this.basketService.getBasketList('productStoredInstanceTable');
  }

  public isProductStoredInstancesInBasket(): boolean {
    return this.getProductStoredInstanceFromBasket().length > 0;
  }
}
