import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BasePrimengTableDirective} from '../../base-primeng-table/base-primeng-table.directive';
import {WorkspaceGroup, WorkspaceInstance} from '../../../core/sdk/bighero-model';
import {SpinnerService} from '../../services/spinner.service';
import {PTableControlService} from '../../services/p-table-control.service';
import {BaseSubNavbarService} from '../base-sub-navbar/base-sub-navbar.service';
import {SearchCriteriaService} from '../../../core/search-criteria.service';
import {TableRefreshService} from '../../services/table-refresh.service';
import {WorkspaceGroupService} from '../../services/production/workspace-group.service';
import {NotificationService} from '../../services/notification.service';
import {ProductionDeviceModel} from '../../../core/sdk/model-productiondevices';
import {HttpErrorResponse} from '@angular/common/http';
import {SelectItem} from 'primeng/api';
import {WorkspaceGroupType} from '../../../core/sdk/enums-types';
import {TranslationKeyEnum} from '../../enums/translation-key-enum';
import {EnumService} from '../../services/enum.service';
import {CrossFunctionalService} from '../../services/cross-functional.service';
import {Router} from '@angular/router';
import {DryerService} from '../../services/production/dryer.service';
import {Utility} from '../../utilities/utility';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {ProductionDeviceModelForAssigningWorkspaceGroup} from '../../interfaces/cross-functional-interfaces/cross-functional-interfaces';
import {FurnaceService} from '../../services/production/furnace.service';
import {MachineService} from '../../services/production/machine.service';
import {ProductionDeviceModelService} from '../../services/production/production-device-model.service';

@Component({
  selector: 'app-workspace-groups-for-production-device-model',
  templateUrl: './workspace-groups-for-production-device-model.component.html',
})
export class WorkspaceGroupsForProductionDeviceModelComponent extends BasePrimengTableDirective<WorkspaceGroup> implements OnInit,
  AfterViewInit, OnDestroy {

  public availableWorkspaceGroupTypeOptions: SelectItem[];
  public selectedWorkspaceGroupType: number;
  public workspaceGroupNameFieldValue: string;
  public currentlySelectedWorkspaceGroup: WorkspaceGroup;
  public showWorkspaceInstanceTableForSelectedWorkspaceGroupFlag = false;
  public isCrossFunctionalActive = false;
  private workspaceGroupFromCross: WorkspaceGroup;

  @Input() public addWorkspaceGroupFlag: boolean;
  @Input() public showAssignButtonForWorkspaceInstance = false;
  @Input() public productionDeviceModelObjectForCross: ProductionDeviceModelForAssigningWorkspaceGroup;
  @Output() public chosenWorkspaceInstanceEmitter: EventEmitter<WorkspaceInstance> = new EventEmitter<WorkspaceInstance>();
  @Output() public closeComponentEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private workspaceGroupService: WorkspaceGroupService,
              private notificationService: NotificationService,
              private enumService: EnumService,
              private crossFunctionalService: CrossFunctionalService,
              private router: Router,
              private dryerService: DryerService,
              private furnaceService: FurnaceService,
              private machineService: MachineService,
              private productionDeviceModelService: ProductionDeviceModelService,
              protected spinnerService: SpinnerService,
              protected pTableControlService: PTableControlService,
              protected baseSubNavbarService: BaseSubNavbarService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService) {
    super(searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService, pTableControlService);
    this.assignClassName('WorkspaceGroup');
    this.assignTableName('workspaceGroupForProductionDeviceModelTable');
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.availableWorkspaceGroupTypeOptions = this.enumService.transformEnumOptionsForPrimeDropdown(WorkspaceGroupType,
      TranslationKeyEnum.PRODUCTION);
    if (this.crossFunctionalService.isCrossFunctionalActive('workspaceGroupForProductionDeviceModel')) {
      this.workspaceGroupFromCross = this.crossFunctionalService.getGatheredData('workspaceGroupForProductionDeviceModel');
    }
    if (this.workspaceGroupFromCross != null) {
      this.checkIfWorkspaceGroupIsDoubledThenAssign(this.workspaceGroupFromCross);
    }
  }

  public ngAfterViewInit(): void {
    if (!this.workspaceGroupFromCross) {
      this.refreshTable();
    }
  }

  private checkIfWorkspaceGroupIsDoubledThenAssign(workspaceGroupFromCross: WorkspaceGroup): void {
    const doubledRecords = this.workspaceGroupService.workspaceGroupsForProductionDeviceModelData
      .filter(item => item.id === workspaceGroupFromCross.id).length > 0;
    if (doubledRecords) {
      this.notificationService.displayNotificationToast('production.chosen-workspace-group-is-already-assigned',
        NotificationMessageType.WARNING);
      this.spinnerService.deactivateSpinner();
    } else {
      workspaceGroupFromCross.productionDeviceModel = this.productionDeviceModelObjectForCross.productionDeviceModel;
      this.productionDeviceModelObjectForCross.productionDeviceModel.workspaceGroupList.push(workspaceGroupFromCross);
      this.performActionOnProductionDeviceModelBasedOnWorkspaceGroupType(workspaceGroupFromCross.type, false);
    }
  }

  public checkWorkspaceGroupDetails(workspaceGroup: WorkspaceGroup): void {
    this.pTableControlService.pushExpandedRowToMap('workspaceGroupTable', workspaceGroup.id);
    this.router.navigateByUrl('bh/production/workspace-group-management');
  }


  public unAssignWorkspaceGroup(workspaceGroup: WorkspaceGroup): void {
    this.spinnerService.activateSpinner();
    this.workspaceGroupService.unassignProductionDeviceModelFromWorkspaceGroup(workspaceGroup.id).subscribe({
      next: (response: WorkspaceGroup) => {
        this.performActionOnProductionDeviceModelBasedOnWorkspaceGroupType(workspaceGroup.type, true, workspaceGroup);
        this.removeWorkspaceGroupFromTable(response);
        this.notificationService.displayNotificationToast('production.workspace-group-unassigned-successfully',
          NotificationMessageType.SUCCESS);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private removeWorkspaceGroupFromTable(workspaceGroupToRemove: WorkspaceGroup): void {
    const index = this.workspaceGroupService.workspaceGroupsForProductionDeviceModelData
      .findIndex(item => item.id === workspaceGroupToRemove.id);
    this.workspaceGroupService.workspaceGroupsForProductionDeviceModelData.splice(index, 1);
  }

  private performActionOnProductionDeviceModelBasedOnWorkspaceGroupType(workspaceGroupType: WorkspaceGroupType,
                                                                        removeMode: boolean, workspaceGroup?: WorkspaceGroup): void {
    let dataToProceed: ProductionDeviceModel[];
    switch (workspaceGroupType) {
      case WorkspaceGroupType.DryingArea: {
        dataToProceed = this.dryerService.dryerModelData;
        break;
      }
      case WorkspaceGroupType.OvenOperation: {
        dataToProceed = this.furnaceService.furnaceModelData;
        break;
      }
      case WorkspaceGroupType.MachineOperation: {
        dataToProceed = this.machineService.getMachineModelData();
        break;
      }
    }
    if (removeMode) {
      this.removeWorkspaceGroupFromProductionDeviceModel(dataToProceed, workspaceGroup.id);
    } else {
      this.updateCurrentModel(dataToProceed);
    }
  }

  private removeWorkspaceGroupFromProductionDeviceModel(data: ProductionDeviceModel[], workspaceGroupId: string): void {
    for (const index in data) {
      if (data[index].id === this.productionDeviceModelObjectForCross.productionDeviceModel.id) {
        Utility.deleteObjectFromArrayById(data[index].workspaceGroupList, workspaceGroupId);
      }
    }
  }

  private updateCurrentModel(dataToUpdate: ProductionDeviceModel[]): void {
    this.productionDeviceModelService.updateProductionDeviceModel(this.productionDeviceModelObjectForCross.productionDeviceModel)
      .subscribe({
        next: (response: ProductionDeviceModel) => {
          Utility.updateObjectInData(response, dataToUpdate);
          this.spinnerService.deactivateSpinner();
          this.notificationService.displayNotificationToast('production.device-model-updated-successfully',
            NotificationMessageType.SUCCESS);
        },
        error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
  }

  public assignNewValuesToTable(data: WorkspaceGroup[]): void {
    this.workspaceGroupService.workspaceGroupsForProductionDeviceModelData = data;
  }

  public getTableData(): WorkspaceGroup[] {
    return this.workspaceGroupService.workspaceGroupsForProductionDeviceModelData;
  }

  public doCount(): void {
    this.workspaceGroupService.countWorkspaceGroupsForProductionDeviceModel(this.searchCriteriaForClass,
      this.productionDeviceModelObjectForCross.productionDeviceModel.id).subscribe({
      next: (response: number) => this.pageable.count = response,
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public doLoad(): void {
    this.workspaceGroupService.getWorkspaceGroupsForProductionDeviceModel(this.searchCriteriaForClass,
      this.productionDeviceModelObjectForCross.productionDeviceModel.id,
      this.pageable.pageNumber, this.pageable.pageSize).subscribe({
      next: (response: WorkspaceGroup[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public displayWorkspaceGroupType(enumItem: number): string {
    return this.enumService.getEnumValue(enumItem, WorkspaceGroupType);
  }

  public getAvailableWorkspaceGroupTypeOptions(): SelectItem[] {
    return this.availableWorkspaceGroupTypeOptions;
  }

  public addNewWorkspaceGroup(): void {
    this.crossFunctionalService.setGatheredData('initAssigningWorkspaceGroup', this.productionDeviceModelObjectForCross);
    this.router.navigate(['bh/production/workspace-group-management']);
  }

  public checkWorkspaceInstancesForWorkspaceGroup(workspaceGroup: WorkspaceGroup): void {
    this.currentlySelectedWorkspaceGroup = workspaceGroup;
    this.toggleShowWorkspaceInstanceTableForSelectedWorkspaceGroupFlag();
  }

  public toggleShowWorkspaceInstanceTableForSelectedWorkspaceGroupFlag(): void {
    this.showWorkspaceInstanceTableForSelectedWorkspaceGroupFlag = !this.showWorkspaceInstanceTableForSelectedWorkspaceGroupFlag;
  }

  public resetFilters(): void {
    this.workspaceGroupNameFieldValue = null;
    this.selectedWorkspaceGroupType = null;
    this.tableRefreshService.onTableRefresh(this.tableName, true);
  }

  public emitChosenWorkspaceInstanceToAssignForProductionDeviceInstance(workspaceInstance: WorkspaceInstance): void {
    this.chosenWorkspaceInstanceEmitter.emit(workspaceInstance);
    this.toggleShowWorkspaceInstanceTableForSelectedWorkspaceGroupFlag();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.currentlySelectedWorkspaceGroup = null;
    this.crossFunctionalService.flushData('workspaceGroupForProductionDeviceModel');
  }

}
