import {Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ListAnimationComponent} from './components/animation/list/list-animation.component';
import {InjectorClass} from './injectorClass';
import {MinutesToDurationPipe} from './pipes/minutes-to-duration.pipe';
import {TableModule} from 'primeng/table';
import {TranslateModule} from '../core/translations/translate.module';
import {BaseFormModule} from './components/base-form/base-form.module';
import {BaseSubNavbarComponent} from './base-components/base-sub-navbar/base-sub-navbar.component';
import {RouterModule} from '@angular/router';
import {
  CustomerAdditionalAddressLineComponent
} from '../components/customers/customer-additional-address-line/customer-additional-address-line.component';
import {ButtonModule} from 'primeng/button';
import {SecuredImageComponent} from './components/secured-image/secured-image.component';
import {EnumTranslationsComponent} from './enum-translations/enum-translations.component';
import {FilesTableComponent} from './components/files-table/files-table.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {SchemaStepListComponent} from './schemas/schema-step-list/schema-step-list.component';
import {
  SchemaTemplateManagementComponent
} from './schemas/schema-template-management/schema-template-management.component';
import {
  SchemaTemplateOverviewTileComponent
} from './schemas/schema-template-management/schema-template-overview-tile/schema-template-overview-tile.component';
import {
  ProductionSchemaStepFormComponent
} from './schemas/schema-step-list/production-schema-step/production-schema-step-form/production-schema-step-form.component';
import {
  ProductionSchemaStepComponent
} from './schemas/schema-step-list/production-schema-step/production-schema-step.component';
import {FiringSchemaStepComponent} from './schemas/schema-step-list/firing-schema-step/firing-schema-step.component';
import {
  FiringSchemaStepFormComponent
} from './schemas/schema-step-list/firing-schema-step/firing-schema-step-form/firing-schema-step-form.component';
import {DropdownModule} from 'primeng/dropdown';
import {BasketComponent} from './basket/basket.component';
import {
  CustomSortFilteringFieldsComponent
} from './base-components/custom-sort-filtering-fields/custom-sort-filtering-fields.component';
import {CalendarModule} from 'primeng/calendar';
import {RxStompService, StompConfig, StompService} from '@stomp/ng2-stompjs';
import {
  DynamicFunctionalityContainerComponent
} from './base-components/base-sub-navbar/dynamic-functionality-container.component';
import {
  MissingDataPlaceholderComponent
} from './components/missing-data-placeholder/missing-data-placeholder.component';
import {
  IntegerInputValidationDirective
} from './input-validation-directives/integer-input-validation-directive/integer-input-validation.directive';
import {
  StringLengthInputValidationDirective
} from './input-validation-directives/string-length-input-validation-directive/string-length-input-validation.directive';
import {ProductionLogTileComponent} from './base-components/production-log-tile/production-log-tile.component';
import {PrintModalComponent} from './printable-components/print-modal/print-modal.component';
import {
  PrintableOrderPackageLabelComponent
} from './printable-components/printable-order-package-label/printable-order-package-label.component';
import {InsertionDirective} from './printable-components/print-modal/insertion.directive';
import {
  ImageTerminalPreviewComponent
} from './printable-components/image-terminal-preview/image-terminal-preview.component';
import {LastStringAfterDotPipe} from './pipes/last-string-after-dot.pipe';
import {PricePipe} from './pipes/price.pipe';
import {PanelModule} from 'primeng/panel';
import {MobileInventoryComponent} from './base-components/mobile/mobile-inventory/mobile-inventory.component';
import {InvoiceDocumentFileComponent} from './base-components/invoice-document-file/invoice-document-file.component';
import {InvoiceActionComponent} from './base-components/invoice-document-file/invoice-action/invoice-action.component';
import {BaseDetailsDisplayComponent} from './base-components/base-details-display/base-details-display.component';
import {WorkloadTableComponent} from './workload/workload-table/workload-table.component';
import {WorkloadTilesComponent} from './workload/workload-tiles/workload-tiles.component';
import {WorkloadTileComponent} from './workload/workload-tiles/workload-tile/workload-tile.component';
import {DeviceInstancesComponent} from './base-components/device-instances/device-instances.component';
import {
  DeviceInstancesCreateFormComponent
} from './base-components/device-instances/device-instances-create-form/device-instances-create-form.component';
import {PrintBarcodeLabelComponent} from './base-components/print-barcode-label/print-barcode-label.component';
import {RockerTabNavbarComponent} from './base-components/rocker-tab-navbar/rocker-tab-navbar.component';
import {
  ProductionDeviceGroupsComponent
} from './base-components/device-instances/production-device-groups/production-device-groups.component';
import {NgxBarcodeModule} from 'ngx-barcode';
import {CurrencyTableComponent} from '../components/admin/currency-management/currency-table/currency-table.component';
import {
  CountryManagementTableComponent
} from './base-components/country-management-table/country-management-table.component';
import {CountryFormComponent} from './base-components/country-management-table/country-form/country-form.component';
import {
  CountryDetailsComponent
} from './base-components/country-management-table/country-details/country-details.component';
import {TabsComponent} from './base-components/tabs/tabs/tabs.component';
import {TabComponent} from './base-components/tabs/tab/tab.component';
import {CheckboxModule} from 'primeng/checkbox';
import {
  WorkspaceGroupsForProductionDeviceModelComponent
} from './base-components/workspace-groups-for-production-device-model/workspace-groups-for-production-device-model.component';
import {
  WorkspaceInstancesForWorkspaceGroupComponent
} from './base-components/workspace-groups-for-production-device-model/workspace-instances-for-workspace-group/workspace-instances-for-workspace-group.component';
import {VirtualKeyboardDirective} from './directives/virtual-keyboard.directive';
import {
  DoubleInputValidationDirective
} from './input-validation-directives/double-input-validation-directive/DoubleInputValdiationDirective';
import {UserDecisionModalComponent} from './base-components/user-decision-modal/user-decision-modal.component';
import {
  PlaceHolderUserModalDecisionDirective
} from './directives/place-holder-user-modal-decision.directive';
import {NotificationModalComponent} from './base-components/notification-modal/notification-modal.component';
import {PlaceholderNotificationModalDirective} from './directives/placeholder-notification-modal.directive';

@NgModule({
  declarations: [
    ListAnimationComponent,
    MinutesToDurationPipe,
    BaseSubNavbarComponent,
    CustomerAdditionalAddressLineComponent,
    SecuredImageComponent,
    EnumTranslationsComponent,
    FilesTableComponent,
    SpinnerComponent,
    SchemaStepListComponent,
    SchemaTemplateManagementComponent,
    SchemaTemplateOverviewTileComponent,
    ProductionSchemaStepFormComponent,
    ProductionSchemaStepComponent,
    FiringSchemaStepComponent,
    FiringSchemaStepFormComponent,
    BasketComponent,
    CustomSortFilteringFieldsComponent,
    DynamicFunctionalityContainerComponent,
    MissingDataPlaceholderComponent,
    IntegerInputValidationDirective,
    StringLengthInputValidationDirective,
    ProductionLogTileComponent,
    PrintModalComponent,
    PrintableOrderPackageLabelComponent,
    InsertionDirective,
    ImageTerminalPreviewComponent,
    LastStringAfterDotPipe,
    PricePipe,
    MobileInventoryComponent,
    InvoiceDocumentFileComponent,
    InvoiceActionComponent,
    BaseDetailsDisplayComponent,
    WorkloadTableComponent,
    WorkloadTilesComponent,
    WorkloadTileComponent,
    DeviceInstancesComponent,
    DeviceInstancesCreateFormComponent,
    PrintBarcodeLabelComponent,
    RockerTabNavbarComponent,
    ProductionDeviceGroupsComponent,
    CurrencyTableComponent,
    CountryManagementTableComponent,
    CountryFormComponent,
    CountryDetailsComponent,
    TabsComponent,
    TabComponent,
    WorkspaceGroupsForProductionDeviceModelComponent,
    WorkspaceInstancesForWorkspaceGroupComponent,
    VirtualKeyboardDirective,
    DoubleInputValidationDirective,
    UserDecisionModalComponent,
    PlaceHolderUserModalDecisionDirective,
    NotificationModalComponent,
    PlaceholderNotificationModalDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    TableModule,
    BaseFormModule,
    RouterModule,
    ButtonModule,
    DropdownModule,
    CalendarModule,
    PanelModule,
    NgxBarcodeModule,
    CheckboxModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    MinutesToDurationPipe,
    TranslateModule,
    ReactiveFormsModule,
    TableModule,
    BaseSubNavbarComponent,
    CustomerAdditionalAddressLineComponent,
    EnumTranslationsComponent,
    SecuredImageComponent,
    FilesTableComponent,
    SchemaStepListComponent,
    SchemaTemplateManagementComponent,
    SchemaTemplateOverviewTileComponent,
    ProductionSchemaStepFormComponent,
    ProductionSchemaStepComponent,
    FiringSchemaStepComponent,
    FiringSchemaStepFormComponent,
    BasketComponent,
    CustomSortFilteringFieldsComponent,
    MissingDataPlaceholderComponent,
    IntegerInputValidationDirective,
    StringLengthInputValidationDirective,
    ProductionLogTileComponent,
    PrintModalComponent,
    InsertionDirective,
    ImageTerminalPreviewComponent,
    LastStringAfterDotPipe,
    PricePipe,
    MobileInventoryComponent,
    InvoiceDocumentFileComponent,
    BaseDetailsDisplayComponent,
    WorkloadTilesComponent,
    WorkloadTableComponent,
    DeviceInstancesComponent,
    PrintBarcodeLabelComponent,
    RockerTabNavbarComponent,
    CurrencyTableComponent,
    CountryManagementTableComponent,
    TabsComponent,
    TabComponent,
    VirtualKeyboardDirective,
    DoubleInputValidationDirective,
    PlaceHolderUserModalDecisionDirective,
    PlaceholderNotificationModalDirective,
  ],
  providers: [
    RxStompService,
    StompService, {
      provide: StompConfig
    },
  ],
})
export class SharedModule {
  constructor(public injector: Injector) {
    InjectorClass.injector = injector;
  }
}
