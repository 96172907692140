import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BasePrimengTableDirective} from '../../../../shared/base-primeng-table/base-primeng-table.directive';
import {Currency} from '../../../../core/sdk/model-currency';
import {SpinnerService} from '../../../../shared/services/spinner.service';
import {BaseSubNavbarService} from '../../../../shared/base-components/base-sub-navbar/base-sub-navbar.service';
import {SearchCriteriaService} from '../../../../core/search-criteria.service';
import {TableRefreshService} from '../../../../shared/services/table-refresh.service';
import {PTableControlService} from '../../../../shared/services/p-table-control.service';
import {CurrencyService} from '../../../../shared/services/currency.service';
import {NotificationService} from '../../../../shared/services/notification.service';
import {Utility} from '../../../../shared/utilities/utility';

@Component({
  selector: 'app-currency-table',
  templateUrl: './currency-table.component.html',
})
export class CurrencyTableComponent extends BasePrimengTableDirective<Currency> implements OnInit,
  AfterViewInit, OnDestroy {

  constructor(private currencyService: CurrencyService,
              private notificationService: NotificationService,
              protected spinnerService: SpinnerService,
              protected baseSubNavbarService: BaseSubNavbarService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService,
              protected pTableControlService: PTableControlService) {
    super(searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService, pTableControlService);
    this.assignClassName('Currency');
    this.assignTableName('currencyTable');
  }
  public currencyName: string;
  public currencyShortname: string;
  public currencyPricePerUnitInEur: number;

  public dateFilterField: string;

  @Output() currencyEmitter = new EventEmitter<Currency>();

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public ngAfterViewInit(): void {
    this.refreshTable();
  }

  public assignNewValuesToTable(data: Currency[]): void {
    this.currencyService.setCurrencyData(data);
  }

  public getTableData(): Currency[] {
    return this.currencyService.getCurrencyData();
  }

  public doCount(): void {
    this.currencyService.countCurrencies(this.searchCriteriaForClass).subscribe(response => {
      this.pageable.count = response;
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public doLoad(): void {
    this.currencyService.getCurrencies(this.searchCriteriaForClass, this.pageable.pageNumber, this.pageable.pageSize)
      .subscribe(response => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public emitAssignedCurrency(currency: Currency): void {
    this.currencyEmitter.emit(currency);
  }

  public convertTimestampToStringData(timestamp: number): string {
    return Utility.convertTimestampToLocalDateString(timestamp);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public resetFilters(): void {
    this.currencyName = null;
    this.currencyShortname = null;
    this.currencyPricePerUnitInEur = null;
    this.dateFilterField = null;
    this.tableRefreshService.onTableRefresh(this.tableName, true);
  }


}
