/* tslint:disable  max-line-length  align  eofline  no-trailing-whitespace  typedef-whitespace  whitespace   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Country} from './model-address';
import {SearchCriteriaForClass} from './model-dto';
import {TaxRate} from './model-tax';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class TaxRateCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public assignCountriesToTaxRate(countryIDsList: string[], taxRateID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<TaxRate>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'taxRateID', value: taxRateID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<TaxRate>();
    this.httpService.put('/api/tax-rate/w/assign-country', JsonScopedSerializer.stringify(countryIDsList, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countTaxRateCountriesAssigned(taxRateID: string): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'taxRateID', value: taxRateID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<number>();
    this.httpService.get('/api/tax-rate/r/count-countries', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countTaxRates(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/tax-rate/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createTaxRate(taxRate: TaxRate, jsonScope: JsonScope = new JsonScope(false, [])): Observable<TaxRate>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<TaxRate>();
    this.httpService.post('/api/tax-rate/w/create', JsonScopedSerializer.stringify(taxRate, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<TaxRate>  {
   const subject = new Subject<TaxRate>();
    this.httpService.get('/api/tax-rate/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getTaxRateCountriesAssigned(taxRateID: string, pageNumber: number, pageSize: number): Observable<Country[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'taxRateID', value: taxRateID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<Country[]>();
    this.httpService.get('/api/tax-rate/r/get-countries', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getTaxRates(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<TaxRate[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<TaxRate[]>();
    this.httpService.post('/api/tax-rate/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public unassignCountryFromTaxRate(countryID: string, taxRateID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<TaxRate>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'countryID', value: countryID});
  
    queryParamsList.push({name: 'taxRateID', value: taxRateID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<TaxRate>();
    this.httpService.put('/api/tax-rate/w/unassign-country', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateTaxRate(taxRate: TaxRate, jsonScope: JsonScope = new JsonScope(false, [])): Observable<TaxRate>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<TaxRate>();
    this.httpService.put('/api/tax-rate/w/update', JsonScopedSerializer.stringify(taxRate, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

