import {Subject} from 'rxjs';
import {ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef} from '@angular/core';
import {UserDecisionModalComponent} from '../base-components/user-decision-modal/user-decision-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UserDecisionModalService {
  private performUserDecisionAction = new Subject<boolean>();
  public performFullSchemaAction$ = this.performUserDecisionAction.asObservable();

  private viewContainerRef: ViewContainerRef;

  public componentRef: ComponentRef<any>;

  constructor(private componentResolver: ComponentFactoryResolver) {
  }

  public handlePerformingUserDecisionAction(performAction: boolean): void {
    this.performUserDecisionAction.next(performAction);
  }

  public set viewContainerReference(container: ViewContainerRef) {
    this.viewContainerRef = container;
  }

  public openUserDecisionModal(title: string, contentInfo: string): void {
    const factory = this.componentResolver.resolveComponentFactory(UserDecisionModalComponent);
    this.componentRef = this.viewContainerRef.createComponent(factory);

    this.componentRef.instance.title = title;
    this.componentRef.instance.modalContentInfo = contentInfo;

  }

  public closeUserDecisionModal(): void {
    this.viewContainerRef.clear();
  }


}
