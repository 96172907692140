import {Injectable} from '@angular/core';
import {TranslateService} from '../../core/translations/translate.service';
import {SelectItem} from 'primeng/api';
import {EnumEntityCtrl, EnumEntityTranslationCtrl} from '../../core/sdk/controllers-enumentityctrls';
import {EnumTranslation} from '../../core/sdk/model-translations';
import {Observable} from 'rxjs';
import {Utility} from '../utilities/utility';
import {JsonScope} from '../../core/sdk/jsonScope';
import {BaseEnumEntity} from '../../core/sdk/model-enumentity';

@Injectable({
  providedIn: 'root'
})
export class EnumService {

  constructor(private translateService: TranslateService,
              private enumEntityCtrl: EnumEntityCtrl,
              private enumEntityTranslationCtrl: EnumEntityTranslationCtrl) {
  }

  transformEnumOptionsForPrimeDropdown(enumObject: any, translationKey: string): SelectItem[] {
    return Object.keys(enumObject)
      .map(key => enumObject[key])
      .filter(value => typeof value !== 'string')
      .map(key2 => ({
        label: this.translateService.translate(translationKey + '.' + enumObject[key2].toString().toLowerCase().split('_').join('-')),
        value: key2
      }));
  }

  getArrayOfEnums(enumObject: any): string[] {
    const enumsArray: string[] = [];
    Object.keys(enumObject).map(key => enumsArray.push(enumObject[key]));
    return enumsArray;
  }

  getArrayOfEnumsValue(enumObject: any): string[] {
    const enumsValueArray: string[] = [];
    const values: string[] = Object.values(enumObject);
    for (let i = 0; i < values.length / 2; i++) {
      enumsValueArray.push(values[i]);
    }
    return enumsValueArray;
  }

  getEnumValue(enumItem: number, enumObject: any): string {
    return enumObject[enumItem];
  }

  getEnumValueWithModule(enumItem: number, enumObject: any, translationModule: string): string {
    return translationModule + '.' + enumObject[enumItem]?.split('_').join('-').toLowerCase();
  }

  addEnumTranslationList(translations: EnumTranslation[]): Observable<EnumTranslation[]> {
    return this.enumEntityTranslationCtrl.addEnumTranslationList(translations);
  }

  public getAllEnumEntitiesGroupedByType(): Observable<{ [key: string]: BaseEnumEntity[] }> {
    return this.enumEntityCtrl.getAllEnumEntitiesGroupedByType();
  }

  public deleteBaseEnumEntity(entityId: string): Observable<void> {
    return this.enumEntityCtrl.deleteObject(Utility.getObjectId(entityId));
  }

  public updateBaseEnumEntity(entity: BaseEnumEntity): Observable<BaseEnumEntity> {
    return this.enumEntityCtrl.updateObject(entity, new JsonScope(false, ['language']));
  }

  public getNewBaseEnumEntity(specificId?: string): BaseEnumEntity {
    return {
      id: (specificId ?? 'base-enum-entity') + '/' + Utility.getUUID(),
      version: 0,
      timestamp: Date.now(),
      name: '',
      translations: []
    };
  }
}
