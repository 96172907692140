export enum FormFieldTypeEnum {
  STRING_INPUT,
  TEXTAREA,
  PASSWORD,
  SELECT,
  COLOR_PICKER,
  FILE_UPLOAD,
  CHECKBOX,
  NUMBER_INPUT
}

export enum MoldManagementRocketSwitchLeftSideButtonName {
  SINGLE_PART_MOLD_TYPES = 'production.single-part-mold-types',
  MULTI_PART_MOLD_TYPES = 'production.multi-part-mold-types'
}

export enum MoldManagementRockerTabsName {
  MOLD_PART_TYPES = 'production.mold-part-types',
  MOLD_TYPES = 'production.mold-types'
}

export enum MoldTypeManagementTableClassNameEnum {
  MOLD_TYPE = 'MoldType',
  MULTI_PART_MOLD_TYPE = 'MultiPartMoldType'
}

export enum BiDirectionalServiceFunctionalKeysEnum {
  OPEN_MOLD_PART_TYPE_SUB_NAVBAR = 'OpenMoldPartTypeSubNavbar',
  OPEN_MULTI_PART_LEFT_SIDE_SWITCH_TAB = 'OpenMultiPartLeftSideSwitchTab',
  OPEN_MOLD_PART_TYPE_INSTANCE_TAB = 'OpenMoldPartTypeInstanceTab'
}
