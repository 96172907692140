/* tslint:disable  max-line-length  align  eofline  no-trailing-whitespace  typedef-whitespace  whitespace   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Person, WorkspaceGroup, WorkspaceInstance} from './bighero-model';
import {SearchCriteriaForClass} from './model-dto';
import {ProductionDeviceInstance} from './model-productiondevices';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class WorkspaceGroupCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countWorkspaceGroups(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/workspace-groups/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countWorkspaceGroupsForProductionDeviceModel(searchCriteria: SearchCriteriaForClass, productionDeviceModelID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productionDeviceModelID', value: productionDeviceModelID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/workspace-groups/r/count-for-production-device-model', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countWorkspaceGroupsForWorker(searchCriteria: SearchCriteriaForClass, personID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'personID', value: personID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/workspace-groups/r/count-for-worker', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countWorkspaceGroupsWithLeaders(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/workspace-groups/r/count-with-group-leaders', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: WorkspaceGroup, jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceGroup>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceGroup>();
    this.httpService.post('/api/workspace-groups/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public dismissGroupLeader(workspaceGroupId: string, leaderId: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Person[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'workspaceGroupId', value: workspaceGroupId});
  
    queryParamsList.push({name: 'leaderId', value: leaderId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Person[]>();
    this.httpService.post('/api/workspace-groups/w/dismiss-group-leader', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public dismissWorker(workspaceGroupId: string, workerId: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Person[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'workspaceGroupId', value: workspaceGroupId});
  
    queryParamsList.push({name: 'workerId', value: workerId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Person[]>();
    this.httpService.post('/api/workspace-groups/w/dismiss-worker', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<WorkspaceGroup>  {
   const subject = new Subject<WorkspaceGroup>();
    this.httpService.get('/api/workspace-groups/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWorkspaceGroups(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceGroup[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceGroup[]>();
    this.httpService.post('/api/workspace-groups/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWorkspaceGroupsForProductionDeviceModel(searchCriteria: SearchCriteriaForClass, productionDeviceModelID: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceGroup[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productionDeviceModelID', value: productionDeviceModelID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceGroup[]>();
    this.httpService.post('/api/workspace-groups/r/get-for-production-device-model', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWorkspaceGroupsForWorker(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, personID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceGroup[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
  
    queryParamsList.push({name: 'personID', value: personID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceGroup[]>();
    this.httpService.post('/api/workspace-groups/r/get-for-worker', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWorkspaceGroupsWithLeaders(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceGroup[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceGroup[]>();
    this.httpService.post('/api/workspace-groups/r/get-with-group-leaders', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public unassignProductionDeviceModelFromWorkspaceGroup(workspaceGroupID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceGroup>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'workspaceGroupID', value: workspaceGroupID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceGroup>();
    this.httpService.post('/api/workspace-groups/w/unassign-production-device-model', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateGroupLeaderList(workspaceGroupId: string, groupLeaderList: Person[], jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceGroup>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'workspaceGroupId', value: workspaceGroupId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceGroup>();
    this.httpService.post('/api/workspace-groups/w/update-group-leader-list', JsonScopedSerializer.stringify(groupLeaderList, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: WorkspaceGroup, jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceGroup>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceGroup>();
    this.httpService.put('/api/workspace-groups/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateWorkersList(workspaceGroupId: string, workersList: Person[], jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceGroup>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'workspaceGroupId', value: workspaceGroupId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceGroup>();
    this.httpService.post('/api/workspace-groups/w/update-workers-list', JsonScopedSerializer.stringify(workersList, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class WorkspaceInstanceCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public assignProductionDeviceInstance(productionDeviceInstance: ProductionDeviceInstance, workspaceInstanceID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceInstance>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'workspaceInstanceID', value: workspaceInstanceID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceInstance>();
    this.httpService.post('/api/workspace-instance/w/assign-production-device-instance', JsonScopedSerializer.stringify(productionDeviceInstance, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countWorkspaceInstancesForWorkspaceGroup(searchCriteria: SearchCriteriaForClass, workspaceGroupId: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'workspaceGroupId', value: workspaceGroupId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/workspace-instance/r/count-for-workspace-group', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: WorkspaceInstance, jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceInstance>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceInstance>();
    this.httpService.post('/api/workspace-instance/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/workspace-instance/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<WorkspaceInstance>  {
   const subject = new Subject<WorkspaceInstance>();
    this.httpService.get('/api/workspace-instance/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWorkspaceInstancesForWorkspaceGroup(searchCriteria: SearchCriteriaForClass, workspaceGroupId: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceInstance[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'workspaceGroupId', value: workspaceGroupId});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceInstance[]>();
    this.httpService.post('/api/workspace-instance/r/get-for-workspace-group', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWorkspaceInstancesFromWorkCalendarForWorkerAtGroup(workspaceGroupID: string, workerID: string): Observable<WorkspaceInstance[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'workspaceGroupID', value: workspaceGroupID});
  
    queryParamsList.push({name: 'workerID', value: workerID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<WorkspaceInstance[]>();
    this.httpService.get('/api/workspace-instance/r/get-all-for-worker-from-calendar', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: WorkspaceInstance, jsonScope: JsonScope = new JsonScope(false, [])): Observable<WorkspaceInstance>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WorkspaceInstance>();
    this.httpService.put('/api/workspace-instance/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

