import {AboutComponent} from './components/navbar/about/about.component';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthTokenInterceptor} from './core/auth/auth-token-interceptor';
import {CoreModule} from './core/core.module';
import {FormsModule} from '@angular/forms';
import {HomeComponent} from './core/home/home.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NavbarComponent} from './components/navbar/navbar.component';
import {NavbarModule} from './components/navbar/navbar.module';
import {Injector, NgModule} from '@angular/core';
import {UserSelfManagementComponent} from './components/user-self-management/user-self-management.component';
import {PasswordResetService} from './core/password-reset/password-reset.service';
import {NewPasswordService} from './core/new-password/new-password.service';
import {SharedModule} from './shared/shared.module';
import {InjectorClass} from './shared/injectorClass';
import {TranslateModule} from './core/translations/translate.module';
import {LanguageSelectorComponent} from './core/translations/language-selector/language-selector.component';
import {environment} from '../environments/environment';
import {BrowserModule} from '@angular/platform-browser';
import {ColorPickerModule} from 'primeng/colorpicker';
import {BreadcrumbsComponent} from './components/navbar/breadcrumbs/breadcrumbs.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {TableModule} from 'primeng/table';
import {TreeModule} from 'primeng/tree';
import {MenuModule} from 'primeng/menu';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {DropdownModule} from 'primeng/dropdown';
import {ErrorMessageComponent} from './components/error-message/error-message.component';
import {BaseFormModule} from './shared/components/base-form/base-form.module';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {OffersComponent} from './components/offers/offers.component';
import {ProductionComponent} from './components/production/production.component';
import {MachinesManagementComponent} from './components/production/machines-management/machines-management.component';
import {MachineDetailsComponent} from './components/production/machines-management/machine-details/machine-details.component';
import {FullSchemaEditionComponent} from './shared/schemas/full-schema-edition/full-schema-edition.component';
import {NgxBarcodeModule} from 'ngx-barcode';
import {WarehouseComponent} from './components/warehouse/warehouse.component';
import {ExportComponent} from './shared/printable-components/export/export.component';
import {PanelModule} from 'primeng/panel';
import {MobileComponent} from './components/mobile/mobile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CheckboxModule} from 'primeng/checkbox';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    AboutComponent,
    UserSelfManagementComponent,
    LanguageSelectorComponent,
    BreadcrumbsComponent,
    ErrorMessageComponent,
    OffersComponent,
    ProductionComponent,
    MachinesManagementComponent,
    MachineDetailsComponent,
    FullSchemaEditionComponent,
    WarehouseComponent,
    ExportComponent,
    MobileComponent
  ],
  imports: [
    AppRoutingModule,
    FontAwesomeModule,
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    NavbarModule,
    SharedModule,
    FormsModule,
    TranslateModule,
    ColorPickerModule,
    BreadcrumbModule,
    TableModule,
    TreeModule,
    MenuModule,
    ButtonModule,
    RippleModule,
    DropdownModule,
    BaseFormModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    NgxBarcodeModule,
    PanelModule,
    HttpClientModule,
    CheckboxModule,
  ],
  providers: [
    PasswordResetService,
    NewPasswordService,
    MessageService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true},
    {provide: 'TranslationService', useClass: environment.staticTranslationCtrl}
  ],
  exports: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public injector: Injector) {
    InjectorClass.injector = injector;
  }
}
