import {Injectable} from '@angular/core';
import {FurnaceInstanceCtrl, FurnaceModelCtrl} from '../../../core/sdk/controllers-productiondevices';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {FurnaceInstance, FurnaceModel, ProductionDeviceGroup} from '../../../core/sdk/model-productiondevices';
import {Utility} from '../../utilities/utility';
import {ProductionDeviceInstanceStatus} from '../../../core/sdk/enums-statuses';

@Injectable({
  providedIn: 'root'
})
export class FurnaceService {

  public furnaceModelData: FurnaceModel[];

  constructor(private furnaceModelCtrl: FurnaceModelCtrl,
              private furnaceInstanceCtrl: FurnaceInstanceCtrl) {
  }

  /////// INSTANCES


  public getNewFurnaceInstance(furnaceModel: FurnaceModel, productionDeviceGroup: ProductionDeviceGroup,
                               status?: ProductionDeviceInstanceStatus): FurnaceInstance {
    return {
      id: 'furnace-instance/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      deleted: false,
      isDeleted: false,
      shorthand: null,
      group: productionDeviceGroup,
      workspaceInstance: null,
      furnaceModel,
      status: status ?? ProductionDeviceInstanceStatus.READY
    };
  }

  public countFurnaceInstances(searchCriteriaForClass: SearchCriteriaForClass, furnaceModelId: string = null): Observable<number> {
    return this.furnaceInstanceCtrl.countMachineInstances(searchCriteriaForClass, Utility.getObjectId(furnaceModelId));
  }

  public getFurnaceInstances(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number,
                             furnaceModelId: string = null): Observable<FurnaceInstance[]> {
    return this.furnaceInstanceCtrl.getFurnaceInstances(searchCriteriaForClass, pageNumber, pageSize, Utility.getObjectId(furnaceModelId));
  }

  public removeFurnaceInstance(furnaceInstanceId: string): Observable<void> {
    return this.furnaceInstanceCtrl.deleteObject(Utility.getObjectId(furnaceInstanceId));
  }

  public getAllFurnaceInstancesForModel(searchCriteriaFroClass: SearchCriteriaForClass, furnaceModelId: string)
    : Observable<FurnaceInstance[]> {
    return this.furnaceInstanceCtrl.getAllFurnaceInstancesForModel(searchCriteriaFroClass, Utility.getObjectId(furnaceModelId));
  }

  public removeFurnaceInstanceFromFurnaceModelData(furnaceModelId: string, furnaceInstanceToRemoveId: string): void {
    for (const index in this.furnaceModelData) {
      if (this.furnaceModelData[index].id === furnaceModelId) {
        Utility.deleteObjectFromArrayById(this.furnaceModelData[index].furnaceInstanceList, furnaceInstanceToRemoveId);
        break;
      }
    }
  }

  public addNewInstanceToFurnaceModelData(furnaceModelId: string, newFurnaceInstance: FurnaceInstance): void {
    for (const index in this.furnaceModelData) {
      if (this.furnaceModelData[index].id === furnaceModelId) {
        this.furnaceModelData[index].furnaceInstanceList.push(newFurnaceInstance);
        break;
      }
    }
  }

  /////// MODELS

  public setFurnaceModelData(data: FurnaceModel[]): void {
    this.furnaceModelData = data;
  }

  public getFurnaceModelData(): FurnaceModel[] {
    return this.furnaceModelData;
  }

  public getFurnaceModel(furnaceId): Observable<FurnaceModel> {
    return this.furnaceModelCtrl.getObject(Utility.getObjectId(furnaceId));
  }

  getAllFurnaceModels(): Observable<FurnaceModel[]> {
    return this.furnaceModelCtrl.findAll();
  }

  public countFurnaceModels(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.furnaceModelCtrl.countFurnaceModels(searchCriteriaForClass);
  }

  public getFurnaceModels(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number)
    : Observable<FurnaceModel[]> {
    return this.furnaceModelCtrl.getFurnaceModels(searchCriteriaForClass, pageNumber, pageSize);
  }

}
