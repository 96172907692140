
export enum BurningTaskStatus {
CREATED,
RECEIVED
}

export enum EmailStatus {
NOT_SENT,
SENT
}

export enum InventoryProductStatus {
NOT_COUNTED,
IN_COUNTING,
COUNTED,
TO_BE_REPAIRED
}

export enum InventoryStatus {
CREATED,
IN_PROGRESS,
DONE
}

export enum MoldInstanceStatus {
FREE,
IN_USE,
DESTROYED
}

export enum OfferStatus {
CREATED,
REMOVED,
CALCULATED,
READY,
ACCEPTED_BY_CLIENT,
SENT
}

export enum OrderPackageRequestStatus {
CREATED,
TAKEN
}

export enum OrderPackageStatus {
CREATED,
IN_PACKING,
SENT,
DONE
}

export enum OrderProductStatus {
CREATED,
IN_PRODUCTION,
PRODUCED
}

export enum OrderStatus {
CREATED,
ARCHIVED,
READY_FOR_PRODUCTION,
IN_PRODUCTION
}

export enum PickUpProductStatus {
RESERVED,
PICKED_UP
}

export enum ProcessStepStatus {
IN_PRODUCTION,
FINISHED
}

export enum ProductStoredInstanceRequestLocationMarkStatus {
TO_BE_STORED,
STORED
}

export enum ProductStoredInstanceRequestStatus {
WAITING_FOR_LOCATION_ASSIGNING,
READY_FOR_STORAGE,
IN_PROGRESS,
DONE
}

export enum ProductStoredInstanceStatus {
IN_STORING,
STORED
}

export enum ProductionDeviceInstanceStatus {
READY,
OPERATING,
IN_MAINTENANCE
}

export enum SupplierArticleLotStatus {
NOT_ACCOUNTED,
ACCOUNTED
}

export enum SupplierDiscountStatus {
NOT_USED,
USED
}

export enum SupplyOrderStatus {
CREATED
}

export enum SupplyPackageStatus {
CREATED
}

export enum TaskStatus {
ACTIVE,
IN_PROGRESS,
DONE
}

export enum TranslationStatus {
valid
}

export enum WarehouseStatus {
OPERATING
}

