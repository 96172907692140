import {Injectable} from '@angular/core';
import {DeclarationsSummaryDetails, SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {Utility} from '../../utilities/utility';
import {ProcessStep, WorkspaceInstance} from '../../../core/sdk/bighero-model';
import {JsonScope} from '../../../core/sdk/jsonScope';
import {DeclarationCtrl, FaultyDeclarationReasonCtrl} from '../../../core/sdk/controllers-declarations';
import {Declaration, FaultyDeclarationReason} from '../../../core/sdk/model-declarations';

@Injectable({
  providedIn: 'root'
})
export class DeclarationService {

  declarationData: Declaration[];

  constructor(private declarationCtrl: DeclarationCtrl,
              private faultyDeclarationReasonCtrl: FaultyDeclarationReasonCtrl) {
  }

  ////// DECLARATIONS

  getNewDeclaration(goodProductsQuantity: number, faultyProductsQuantity: number,
                    processStep: ProcessStep, workspaceInstance: WorkspaceInstance,
                    faultyProductsReasonList?: FaultyDeclarationReason[]): Declaration {
    return {
      id: 'declaration/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      deleted: false,
      isDeleted: false,
      shorthand: null,
      declarationDate: Date.now(),
      faultyProductReasonList: faultyProductsReasonList ?? null,
      goodProductsQuantity: goodProductsQuantity ?? 0,
      faultyProductsQuantity: faultyProductsQuantity ?? 0,
      processStep,
      workspaceInstance,
      creator: null,
      declarationAfterSupervision: false,
      isDeclarationAfterSupervision: false
    };
  }

  updateDeclaration(declaration: Declaration): Observable<Declaration> {
    return this.declarationCtrl.updateObject(declaration, new JsonScope(false, ['faulty-declaration-reason',
      'process-step', 'person']));
  }

  countDeclarationsForProcessStep(searchCriteriaForClass: SearchCriteriaForClass, processStepId: string): Observable<number> {
    return this.declarationCtrl.countDeclarationsForProcessStep(searchCriteriaForClass, Utility.getObjectId(processStepId));
  }

  getDeclarationsForProcessStep(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number,
                                processStepId: string): Observable<Declaration[]> {
    return this.declarationCtrl.getDeclarationsForProcessStep(searchCriteriaForClass, pageNumber, pageSize,
      Utility.getObjectId(processStepId));
  }

  getDeclarationSummaryDetailsForProcessStep(stepId: string): Observable<DeclarationsSummaryDetails> {
    return this.declarationCtrl.getDeclarationsSummaryDetails(Utility.getObjectId(stepId));
  }

  createDeclarationForWorkerTask(workerTaskId: string, declaration: Declaration): Observable<Declaration> {
    return this.declarationCtrl.createDeclarationForWorkerTask(Utility.getObjectId(workerTaskId), declaration, new JsonScope(false, ['worker', 'person', 'process-step']));
  }

  createDeclarationForBurningProduct(burningProductId: string, declaration: Declaration): Observable<Declaration> {
    return this.declarationCtrl.createDeclarationForBurningProduct(Utility.getObjectId(burningProductId), declaration,
      new JsonScope(false, ['worker', 'person', 'process-step', 'faulty-declaration-reason']));
  }


  /////////// FAULTY_DECLARATION_REASONS

  getNewFaultyDeclarationReason(isDefault?: boolean, requiredSupervision?: boolean, name?: string, description?: string)
    : FaultyDeclarationReason {
    return {
      id: 'faulty-declaration-reason/' + Utility.getUUID(),
      version: 0,
      timestamp: Date.now(),
      default: isDefault ?? true,
      isDefault: isDefault ?? true,
      name: name ?? '',
      description: description ?? '',
      requiredSupervision: requiredSupervision ?? false,
      isRequiredSupervision: requiredSupervision ?? false
    };
  }


  createFaultyDeclarationReason(reason: FaultyDeclarationReason): Observable<FaultyDeclarationReason> {
    return this.faultyDeclarationReasonCtrl.createObject(reason);
  }

  updateFaultyDeclarationReason(reason: FaultyDeclarationReason): Observable<FaultyDeclarationReason> {
    return this.faultyDeclarationReasonCtrl.updateObject(reason);
  }

  deleteFaultyDeclarationReason(reasonId: string): Observable<void> {
    return this.faultyDeclarationReasonCtrl.deleteObject(Utility.getObjectId(reasonId));
  }

  getAllDefaultFaultyDeclarationReasons(): Observable<FaultyDeclarationReason[]> {
    return this.faultyDeclarationReasonCtrl.getAllDefaultReasons();
  }

  ///// DECLARATIONS_FOR_SUPERVISION

  setDeclarationData(data: Declaration[]): void {
    this.declarationData = data;
  }

  getDeclarationData(): Declaration[] {
    return this.declarationData;
  }

  countDeclarationsForSupervision(): Observable<number> {
    return this.declarationCtrl.countDeclarationsForSupervision();
  }

  getDeclarationsForSupervision(): Observable<Declaration[]> {
    return this.declarationCtrl.getDeclarationsForSupervision();
  }

}
