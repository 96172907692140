/* tslint:disable  max-line-length  align  eofline  no-trailing-whitespace  typedef-whitespace  whitespace   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SearchCriteriaForClass} from './model-dto';
import {FileInfo, ProductTypeFile} from './model-files';
import {MoldType} from './model-mold';
import {DummyOfferProduct} from './model-offer';
import {ProductionDeviceModel} from './model-productiondevices';
import {ProductQuality, ProductType, ProductTypeGroup, ProductTypeRecipe, ProductTypeRecipeIngredient} from './model-producttype';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ProductQualityCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countProductQualities(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/product-quality/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: ProductQuality, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductQuality>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductQuality>();
    this.httpService.post('/api/product-quality/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/product-quality/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<ProductQuality>  {
   const subject = new Subject<ProductQuality>();
    this.httpService.get('/api/product-quality/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductQualities(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductQuality[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductQuality[]>();
    this.httpService.post('/api/product-quality/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductQualityAssignedProductionDevices(productQualityID: string): Observable<ProductionDeviceModel[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productQualityID', value: productQualityID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductionDeviceModel[]>();
    this.httpService.get('/api/product-quality/r/get-devices', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ProductQuality, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductQuality>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductQuality>();
    this.httpService.put('/api/product-quality/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ProductTypeCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public changeAssignedMoldType(moldType: MoldType, productTypeID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductType>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productTypeID', value: productTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductType>();
    this.httpService.put('/api/product-type/w/change-assigned-mold-type', JsonScopedSerializer.stringify(moldType, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countProductTypeFiles(productTypeId: string, drawingFiles: boolean | null): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productTypeId', value: productTypeId});
  
    if (drawingFiles !== undefined && drawingFiles !== null) {
      queryParamsList.push({name: 'drawingFiles', value: drawingFiles.toString()});
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<number>();
    this.httpService.get('/api/product-type/r/files-count', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countProductTypes(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/product-type/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countProductTypesForMoldType(searchCriteria: SearchCriteriaForClass, moldTypeId: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'moldTypeId', value: moldTypeId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/product-type/r/count/mold-type', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: ProductType, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductType>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductType>();
    this.httpService.post('/api/product-type/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createProductTypeFromDummyOfferProduct(dummyOfferProduct: DummyOfferProduct, importProductionSchema: boolean | null, importProductTypeRecipe: boolean | null, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductType>  {
    const queryParamsList: { name: string, value: string }[] = [];
    if (importProductionSchema !== undefined && importProductionSchema !== null) {
      queryParamsList.push({name: 'importProductionSchema', value: importProductionSchema.toString()});
    }

    if (importProductTypeRecipe !== undefined && importProductTypeRecipe !== null) {
      queryParamsList.push({name: 'importProductTypeRecipe', value: importProductTypeRecipe.toString()});
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductType>();
    this.httpService.post('/api/product-type/w/create-product-from-dummy', JsonScopedSerializer.stringify(dummyOfferProduct, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/product-type/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteProductTypeFile(fileId: string): Observable<void>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'fileId', value: fileId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/product-type/w/delete-file', {params})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<ProductType>  {
   const subject = new Subject<ProductType>();
    this.httpService.get('/api/product-type/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductTypeFiles(productTypeId: string, drawingFiles: boolean | null, pageNumber: number, pageSize: number): Observable<ProductTypeFile[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productTypeId', value: productTypeId});
  
    if (drawingFiles !== undefined && drawingFiles !== null) {
      queryParamsList.push({name: 'drawingFiles', value: drawingFiles.toString()});
    }

    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductTypeFile[]>();
    this.httpService.get('/api/product-type/r/files-get', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductTypeManufacturingCost(productTypeID: string): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productTypeID', value: productTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<number>();
    this.httpService.get('/api/product-type/r/get/mfc', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductTypes(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductType[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductType[]>();
    this.httpService.post('/api/product-type/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductTypesForMoldType(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, moldTypeId: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductType[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
  
    queryParamsList.push({name: 'moldTypeId', value: moldTypeId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductType[]>();
    this.httpService.post('/api/product-type/r/get/mold-type', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ProductType, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductType>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductType>();
    this.httpService.put('/api/product-type/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public uploadFile(productTypeId: string, isDrawing: boolean | null, fileName: string, fileExtension: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<FileInfo>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productTypeId', value: productTypeId});
  
    if (isDrawing !== undefined && isDrawing !== null) {
      queryParamsList.push({name: 'isDrawing', value: isDrawing.toString()});
    }

    queryParamsList.push({name: 'fileName', value: fileName});
  
    queryParamsList.push({name: 'fileExtension', value: fileExtension});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<FileInfo>();
    this.httpService.post('/api/product-type/w/upload', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ProductTypeGroupCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObjectByName(name: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductTypeGroup>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'name', value: name});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductTypeGroup>();
    this.httpService.post('/api/product-type-group/w/new', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/product-type-group/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAllEnumObjects(): Observable<ProductTypeGroup[]>  {
   const subject = new Subject<ProductTypeGroup[]>();
    this.httpService.get('/api/product-type-group/r/all', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ProductTypeRecipeCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public bookMaterialsForProduction(productTypeID: string, quantity: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<void>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productTypeID', value: productTypeID});
  
    queryParamsList.push({name: 'quantity', value: quantity.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<void>();
    this.httpService.post<void>('/api/product-type-recipe/w/book-materials-for-production', null , {headers, params})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: ProductTypeRecipe, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductTypeRecipe>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductTypeRecipe>();
    this.httpService.post('/api/product-type-recipe/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/product-type-recipe/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductTypeRecipe(productTypeID: string): Observable<ProductTypeRecipe>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productTypeID', value: productTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductTypeRecipe>();
    this.httpService.get('/api/product-type-recipe/r/get', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ProductTypeRecipe, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductTypeRecipe>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductTypeRecipe>();
    this.httpService.put('/api/product-type-recipe/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ProductTypeRecipeIngredientCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countProductTypeRecipeIngredients(searchCriteria: SearchCriteriaForClass, productTypeRecipeID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productTypeRecipeID', value: productTypeRecipeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/product-type-recipe-ingredient/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: ProductTypeRecipeIngredient, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductTypeRecipeIngredient>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductTypeRecipeIngredient>();
    this.httpService.post('/api/product-type-recipe-ingredient/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/product-type-recipe-ingredient/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductTypeRecipeIngredients(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, productTypeRecipeID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductTypeRecipeIngredient[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
  
    queryParamsList.push({name: 'productTypeRecipeID', value: productTypeRecipeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductTypeRecipeIngredient[]>();
    this.httpService.post('/api/product-type-recipe-ingredient/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ProductTypeRecipeIngredient, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductTypeRecipeIngredient>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductTypeRecipeIngredient>();
    this.httpService.put('/api/product-type-recipe-ingredient/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

