import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../../environments/environment';
import {Utility} from '../../shared/utilities/utility';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstanceValues} from '../../shared/constance-values/constance-values';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  private returnUrl: string;

  public formGroup: FormGroup;
  public isTerminalDevice = false;
  public appName = environment.appName;
  public companyName = environment.companyName;
  public developmentYear = environment.developmentYear;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private authService: AuthService) {
    this.isTerminalDevice = Utility.isTerminalDevice();
  }

  public ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams[`returnUrl`] || '/';
    this.initFormGroup();
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      username: ['', [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]],
      password: ['', [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]]
    });
  }

  public onLogin(): void {
    if (this.formGroup.valid) {
      this.authService.loginWithRedirect(this.usernameControl.value, this.passwordControl.value, this.returnUrl);
    }
  }

  public onPasswordReset(): void {
    this.router.navigate(['/password-reset']);
  }

  public scrollToEmailInput(): void {
    window.scrollBy(0, 500);
  }


  public scrollToPasswordInput(): void {
    window.scrollBy(0, 500);
  }

  public get usernameControl(): AbstractControl {
    return this.formGroup.get('username');
  }

  public get passwordControl(): AbstractControl {
    return this.formGroup.get('password');
  }

  public get usernameInputClass(): string {
    return this.inputClass + (this.usernameControl.invalid && (this.usernameControl.touched || this.usernameControl.dirty) ? ' global-invalid-input-box-shadow' : '');
  }

  public get passwordInputClass(): string {
    return this.inputClass + (this.passwordControl.invalid && (this.passwordControl.touched || this.passwordControl.dirty) ? ' global-invalid-input-box-shadow' : '');
  }

  private get inputClass(): string {
    return this.isTerminalDevice ? 'login__main-container__login-form-container__input--terminal' : 'login__main-container__login-form-container__input--desktop';
  }

}
