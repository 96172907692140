import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Country} from '../../../../core/sdk/model-address';
import {CountryService} from '../../../services/country.service';
import {SpinnerService} from '../../../services/spinner.service';
import {NotificationService} from '../../../services/notification.service';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {Utility} from '../../../utilities/utility';

@Component({
  selector: 'app-country-form',
  templateUrl: './country-form.component.html',
})
export class CountryFormComponent implements OnInit {

  public formGroup: FormGroup;
  @Input() public hideReturnButton = false;
  @Input() public shouldFormBeDisabled = false;
  @Input() public currentCountry: Country;
  @Input() public updateCountryFlag = false;
  @Output() public formSubmitEmitter = new EventEmitter();
  @Output() public formCancelEmitter = new EventEmitter();

  constructor(private formBuilder: FormBuilder,
              private countryService: CountryService,
              private spinnerService: SpinnerService,
              private notificationService: NotificationService) {
  }

  public ngOnInit(): void {
    this.initFormGroup();
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [{value: this.currentCountry?.name ?? '', disabled: this.shouldFormBeDisabled}, [Validators.required]],
      isInsideEuropeanUnion: [{
        value: this.currentCountry?.isInsideEuropeanUnion ?? false,
        disabled: this.shouldFormBeDisabled
      }],
      isOutsideEuropeContinent: [{
        value: this.currentCountry?.isOutsideEuropeContinent ?? false,
        disabled: this.shouldFormBeDisabled
      }]
    });
  }

  public get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  public get isInsideEuropeanUnionControl(): AbstractControl {
    return this.formGroup.get('isInsideEuropeanUnion');
  }

  public get isOutsideEuropeContinent(): AbstractControl {
    return this.formGroup.get('isOutsideEuropeContinent');
  }

  private createNewCountry(): void {
    this.spinnerService.activateSpinner();
    this.countryService.createCountry(this.nameControl.value,
      this.isInsideEuropeanUnionControl.value,
      this.isOutsideEuropeContinent.value)
      .subscribe({
        next: () => {
          this.notificationService.displayNotificationToast('admin.country-created-successfully',
            NotificationMessageType.SUCCESS);
          this.formSubmitEmitter.emit();
          this.spinnerService.deactivateSpinner();
        },
        error: (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
  }

  private updateCountry(): void {
    this.spinnerService.activateSpinner();
    this.currentCountry = {...this.currentCountry, ...this.formGroup.value};
    this.countryService.updateCountry(this.currentCountry, this.currentCountry.id).subscribe({
      next: (response) => {
        Utility.updateObjectInData(response, this.countryService.getCountryData());
        this.notificationService.displayNotificationToast('admin.country-updated-successfully',
          NotificationMessageType.SUCCESS);
        this.spinnerService.deactivateSpinner();
      },
      error: (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public onCancel(): void {
    this.formCancelEmitter.emit();
  }

  public onSubmit(): void {
    if (!this.updateCountryFlag) {
      this.createNewCountry();
    } else {
      this.updateCountry();
    }
  }
}
