import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BiDirectionalService {

  private subscriptionMap: Map<string, Subject<any>> = new Map<string, Subject<any>>();

  public registerSubscriptionMap(functionalKey: string): void {
    this.subscriptionMap.set(functionalKey, new Subject<any>());
  }

  public emitData(functionalKey: string, data: any): void {
    this.subscriptionMap.get(functionalKey).next(data);
  }

  public flushData(functionalKey: string): void {
    this.subscriptionMap.delete(functionalKey);
  }

  public getSubscriptionFromMap(functionalKey: string): Observable<any> {
    if (this.subscriptionMap.get(functionalKey) == null) {
      this.registerSubscriptionMap(functionalKey);
    }
    return this.subscriptionMap.get(functionalKey).asObservable();
  }


}
