import {Injectable} from '@angular/core';
import {WorkspaceInstanceCtrl} from '../../../core/sdk/controllers-workspace';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {WorkspaceGroup, WorkspaceInstance} from '../../../core/sdk/bighero-model';
import {ProductionDeviceInstance} from '../../../core/sdk/model-productiondevices';
import {Utility} from '../../utilities/utility';
import {JsonScope} from '../../../core/sdk/jsonScope';


@Injectable({
  providedIn: 'root'
})
export class WorkspaceInstanceService {

  constructor(private workspaceInstanceCtrl: WorkspaceInstanceCtrl) {
  }

  public getWorkspaceInstancesForWorkspaceGroup(searchCriteria: SearchCriteriaForClass, workspaceGroupId: string,
                                                pageNumber: number, pageSize: number): Observable<WorkspaceInstance[]> {
    return this.workspaceInstanceCtrl.getWorkspaceInstancesForWorkspaceGroup(searchCriteria, workspaceGroupId, pageNumber, pageSize);
  }

  public countWorkspaceInstancesForWorkspaceGroup(searchCriteria: SearchCriteriaForClass, workspaceGroupId: string): Observable<number> {
    return this.workspaceInstanceCtrl.countWorkspaceInstancesForWorkspaceGroup(searchCriteria, workspaceGroupId);
  }

  public getNewWorkspaceInstance(workspaceGroup: WorkspaceGroup, productionDeviceInstance: ProductionDeviceInstance): WorkspaceInstance {
    return {
      shorthand: null,
      id: 'workspace-instance/' + Utility.getUUID(),
      hasProductionDevice: false,
      location: null,
      productionDeviceInstance,
      workspaceGroup,
      timestamp: Date.now(),
      version: 0
    };
  }

  public createWorkspaceInstance(workspaceInstance: WorkspaceInstance): Observable<WorkspaceInstance> {
    return this.workspaceInstanceCtrl.createObject(workspaceInstance);
  }

  public updateWorkspaceInstance(workspaceInstance: WorkspaceInstance): Observable<WorkspaceInstance> {
    return this.workspaceInstanceCtrl.updateObject(workspaceInstance);
  }

  public deleteWorkspaceInstance(workspaceInstanceId: string): Observable<void> {
    return this.workspaceInstanceCtrl.deleteObject(workspaceInstanceId);
  }

  public getWorkspaceInstancesFromWorkCalendarForWorkerAtGroup(workspaceGroupId: string, workerId: string):
    Observable<WorkspaceInstance[]> {
    return this.workspaceInstanceCtrl.getWorkspaceInstancesFromWorkCalendarForWorkerAtGroup(
      Utility.getObjectId(workspaceGroupId),
      Utility.getObjectId(workerId)
    );
  }

  public assignProductionDeviceInstance(productionDeviceInstance: ProductionDeviceInstance, workspaceInstanceId: string)
    : Observable<WorkspaceInstance> {
    return this.workspaceInstanceCtrl.assignProductionDeviceInstance(productionDeviceInstance, Utility.getObjectId(workspaceInstanceId));
  }
}
