import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {
  BurningSchemaStep,
  DryingSchemaStep,
  MoldingSchemaStep,
  PostProcessingSchemaStep,
  ProductionSchemaStep
} from '../../../../core/sdk/model-productionschema';
import {SchemaService} from '../../../services/schema.service';
import {ProductionSchemaStepType} from '../../../../core/sdk/enums-types';
import {Utility} from '../../../utilities/utility';
import {NotificationService} from '../../../services/notification.service';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {CrossFunctionalService} from '../../../services/cross-functional.service';
import {MoldType} from '../../../../core/sdk/model-mold';
import {FiringSchema} from '../../../../core/sdk/model-firingschema';
import {TranslateService} from '../../../../core/translations/translate.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstanceValues} from '../../../constance-values/constance-values';
import {SpinnerService} from '../../../services/spinner.service';
import {ProductTypeService} from '../../../services/product-type.service';

@Component({
  selector: 'app-production-schema-step',
  templateUrl: './production-schema-step.component.html'
})
export class ProductionSchemaStepComponent implements OnInit {

  public formGroup: FormGroup;
  public editStepDescriptionFlag = false;
  public stepFormFlag = false;
  public moldType: MoldType;
  public firingSchema: FiringSchema;
  public weightReduction: number;
  public quantityOfMiddleProductsAfterPostProcessing: number;

  public isTerminalDevice = false;

  @Input() public step: ProductionSchemaStep;
  @Input() public indexColumnApplied = false;
  @Input() public closeAllStepEditionEmitter: EventEmitter<void>;

  constructor(private schemaService: SchemaService,
              private productTypeService: ProductTypeService,
              private translateService: TranslateService,
              private notificationService: NotificationService,
              private formBuilder: FormBuilder,
              private crossFunctionalService: CrossFunctionalService,
              private spinnerService: SpinnerService) {
    this.isTerminalDevice = Utility.isTerminalDevice();
  }

  public ngOnInit(): void {
    this.initFormGroup();
    if (this.crossFunctionalService.getGatheredData('currentlyEditedStepId') === this.step.id) {
      this.stepFormFlag = true;
    }
    if (this.stepFormFlag) {
      this.crossFunctionalService.flushData('currentlyEditedStepId');
    }
    this.assignExclusiveValues();
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [this.step.name ?? '', [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]],
      description: [this.step.description ?? '', [Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]]
    });
  }

  private assignExclusiveValues(): void {
    if (this.step.id.includes('burning')) {
      this.firingSchema = (this.step as BurningSchemaStep).firingSchema;
    } else if (this.step.id.includes('molding')) {
      this.moldType = (this.step as MoldingSchemaStep).moldType;
    } else if (this.step.id.includes('drying')) {
      this.weightReduction = (this.step as DryingSchemaStep).weightReduction ?? 0;
    } else if (this.step.id.includes('post-processing')) {
      this.quantityOfMiddleProductsAfterPostProcessing =
        (this.step as PostProcessingSchemaStep).quantityOfMiddleProductsAfterPostProcessing;
    }
  }

  public toggleEditDescriptionFlag(): void {
    this.editStepDescriptionFlag = !this.editStepDescriptionFlag;
  }


  public toggleStepFormFlag(): void {
    this.stepFormFlag = !this.stepFormFlag;
  }

  public removeProductionSchemaStep(): void {
    this.spinnerService.activateSpinner();
    if (!this.indexColumnApplied) {
      this.spinnerService.activateSpinner();
      this.schemaService.removeSchemaStep(this.step.id).subscribe(response => {
        this.productTypeService.updateSchemaForProductTypeInData(response);
        this.schemaService.assignSchemaStepsToData(response.productionSchemaStepList);
        this.spinnerService.deactivateSpinner();
        this.notificationService.displayNotificationToast('products.step-deleted-successfully', NotificationMessageType.SUCCESS);
        this.spinnerService.deactivateSpinner();
      });
    } else {
      this.schemaService.removeStepFromData(this.step);
      this.spinnerService.deactivateSpinner();
    }
  }

  public updateProductionSchemaStepDescription(): void {
    this.step = {...this.step, ...this.formGroup.value};
    this.schemaService.updateSchemaStep(this.step).subscribe(response => {
      this.step = response as ProductionSchemaStep;
      Utility.updateObjectInData(response, this.schemaService.schemaSteps);
      this.notificationService.displayNotificationToast('products.step-updated-successfully', NotificationMessageType.SUCCESS);
      this.toggleEditDescriptionFlag();
    }, (error) => {
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
  }


  public changeIndexHigher(): void {
    this.schemaService.changeIndexHigher(this.step);
  }


  public changeIndexLower(): void {
    this.schemaService.changeIndexLower(this.step);
  }

  public getTranslatedValueOfStepType(value: ProductionSchemaStepType): string {
    return this.schemaService.getTranslatedValueOfStepType(value, ProductionSchemaStepType);
  }

  public transformSecondsToDurationObject(seconds: number): { hours: number, minutes: number, seconds: number, days: number } {
    return this.schemaService.transformSeconds(seconds);
  }

  public updateStep(step?: ProductionSchemaStep): void {
    if (step) {
      this.schemaService.updateEditedStepInSchemaSteps(step);
      this.assignExclusiveValues();
    } else if (this.schemaService.isItNewStep(this.step.id)) {
      this.schemaService.removeStepFromData(this.step);
    }
    this.stepFormFlag = false;
  }

  public transformDurationObjectToString(duration: { hours: number, minutes: number, seconds: number, days: number }): string {
    return Utility.transformDurationObjectToUserFriendlyString(duration, this.translateService);
  }

  public get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  public get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

}
