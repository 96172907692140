import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FiringSchemaStep} from '../../../../core/sdk/model-firingschema';
import {NotificationService} from '../../../services/notification.service';
import {FiringSchemaTemperatureFluctuation} from '../../../../core/sdk/model-enums';
import {SchemaService} from '../../../services/schema.service';
import {Utility} from '../../../utilities/utility';
import {CrossFunctionalService} from '../../../services/cross-functional.service';
import {TranslateService} from '../../../../core/translations/translate.service';

@Component({
  selector: 'app-firing-schema-step',
  templateUrl: './firing-schema-step.component.html',
  styles: []
})
export class FiringSchemaStepComponent implements OnInit {
  @Input() step: FiringSchemaStep;
  @Input() closeAllStepEditionEmitter: EventEmitter<void>;

  stepFormFlag = false;

  constructor(private notificationService: NotificationService,
              private schemaService: SchemaService,
              private translateService: TranslateService,
              private crossFunctionalService: CrossFunctionalService) {
  }

  ngOnInit(): void {
    this.stepFormFlag = this.crossFunctionalService.getGatheredData('currentlyEditedStepId') === this.step.id;
    if (this.stepFormFlag) {
      this.crossFunctionalService.flushData('currentlyEditedStepId');
    }
  }

  toggleEditFlag(): void {
    this.stepFormFlag = !this.stepFormFlag;
  }

  removeFiringSchemaStep(): void {
    this.schemaService.removeStepFromData(this.step);
  }

  changeIndexHigher(): void {
    this.schemaService.changeIndexHigher(this.step);
  }

  changeIndexLower(): void {
    this.schemaService.changeIndexLower(this.step);
  }

  getEnumValue(value: FiringSchemaTemperatureFluctuation): string {
    return this.schemaService.getTranslatedValueOfStepType(value, FiringSchemaTemperatureFluctuation);
  }

  transformSecondsToDurationObject(timestamp: number): { hours: number, minutes: number, seconds: number, days: number } {
    return this.schemaService.transformSeconds(timestamp);
  }

  transformDurationObjectToString(duration: { hours: number, minutes: number, seconds: number, days: number }): string {
    return Utility.transformDurationObjectToUserFriendlyString(duration, this.translateService);
  }

  updateStep(step?: FiringSchemaStep): void {
    if (step) {
      Utility.updateObjectInData(step, this.schemaService.schemaSteps);
    } else if (this.schemaService.isItNewStep(this.step.id)) {
      this.schemaService.removeStepFromData(this.step);
    }
    this.toggleEditFlag();
  }

}
