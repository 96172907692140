import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Customer} from '../../../core/sdk/bighero-model';
import {SearchCriteriaService} from '../../../core/search-criteria.service';
import {TableRefreshService} from '../../services/table-refresh.service';
import {CustomerService} from '../../services/customer.service';
import {Utility} from '../../utilities/utility';
import {HttpClient} from '@angular/common/http';
import {ErrorHandlerService} from '../../../core/sdk/error-handler.service';
import {NotificationService} from '../../services/notification.service';
import {CustomerFile, FileInfo, OfferFile, ProductTypeFile} from '../../../core/sdk/model-files';
import {FileService} from '../../services/file.service';
import {v4 as uuid} from 'uuid';
import {OfferService} from '../../services/offer.service';
import {Observable} from 'rxjs';
import {SpinnerService} from '../../services/spinner.service';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {Offer} from '../../../core/sdk/model-offer';
import {BasePrimengTableDirective} from '../../base-primeng-table/base-primeng-table.directive';
import {ProductType} from '../../../core/sdk/model-producttype';
import {ProductTypeService} from '../../services/product-type.service';

@Component({
  selector: 'app-files-table',
  templateUrl: './files-table.component.html'
})
export class FilesTableComponent extends BasePrimengTableDirective<FileInfo> implements OnInit, AfterViewInit, OnDestroy {

  public itemID = uuid();
  public selectedPathToFile: string;
  private file: File;
  public paramsMap: Map<string, string>;
  public isDrawingFile: boolean;

  public isTerminalDevice: boolean;

  @ViewChild('fileInput') public fileInput;
  @Input() public currentOwner: Customer | Offer | ProductType;
  @Input() public currentService: CustomerService | OfferService;
  @Input() changeFileTypeEvent: Observable<boolean>;

  @Input('isDrawing') set setParamsMap(isDrawing: boolean) {
    this.isDrawingFile = isDrawing;
    this.paramsMap = new Map<string, string>();
    this.paramsMap.set('isDrawing', String(this.isDrawingFile));
  }

  private filesData: Observable<OfferFile[] | CustomerFile[] | ProductTypeFile[]>;

  constructor(private httpService: HttpClient,
              private errorHandlerService: ErrorHandlerService,
              private notificationService: NotificationService,
              private fileService: FileService,
              private productTypeService: ProductTypeService,
              protected spinnerService: SpinnerService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService) {
    super(searchCriteriaService, tableRefreshService, spinnerService);
    this.assignTableName('fileTable');
    this.isTerminalDevice = Utility.isTerminalDevice();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.pageable.pageSize = 3;
    if (this.changeFileTypeEvent) {
      this.changeFileTypeEvent.subscribe(value => {
        this.isDrawingFile = value;
        this.spinnerService.activateSpinner();
        this.refreshTable();
      });
    }
  }

  public ngAfterViewInit(): void {
    this.refreshTable();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public doCount(): void {
    if (this.isDrawingFile === undefined) {
      this.currentService.countFiles(Utility.getObjectId(this.currentOwner.id)).subscribe(filesCount => {
        this.pageable.count = filesCount;
      }, (error) => {
        this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
        this.spinnerService.deactivateSpinner();
      });
    } else {
      this.countProductTypeFiles();
    }
  }

  private countProductTypeFiles(): void {
    this.productTypeService.countProductTypeFiles(this.currentOwner.id, this.isDrawingFile).subscribe(response => {
      this.pageable.count = response;
    });
  }

  public doLoad(): void {
    if (this.isDrawingFile === undefined) {
      this.filesData = this.currentService.getFiles(Utility.getObjectId(this.currentOwner.id), this.pageable.pageNumber,
        this.pageable.pageSize);
      this.filesData.subscribe((response: FileInfo[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      }, (error) => {
        this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
        this.spinnerService.deactivateSpinner();
      });
    } else {
      this.doLoadProductTypeFiles();
    }
  }

  private doLoadProductTypeFiles(): void {
    this.filesData = this.productTypeService.getProductTypeFiles(this.currentOwner.id, this.isDrawingFile,
      this.pageable.pageNumber, this.pageable.pageSize);
    this.filesData.subscribe((response: ProductTypeFile[]) => {
      this.assignNewValuesToTable(response);
      this.spinnerService.deactivateSpinner();
    });
  }

  public assignNewValuesToTable(data: FileInfo[]): void {
    this.data = data;
  }

  public uploadFile(fileList?: FileList): void {
    const fileBrowser = this.fileInput.nativeElement;
    this.file = fileBrowser.files[0];
    if (fileList && fileList[0]) {
      this.file = fileList.item(0);
    }
    if (this.file) {
      this.spinnerService.activateSpinner();
      this.fileService.uploadFile(this.file, this.file.name,
        this.currentOwner.id.split('/')[0], this.currentOwner.id, this.paramsMap).subscribe((file) => {
        this.data.push(file);
        this.tableRefreshService.onTableRefresh('fileTable');
        this.spinnerService.deactivateSpinner();
        this.notificationService.displayNotificationToast('common.file-upload-successful', NotificationMessageType.SUCCESS);
      }, (error) => {
        this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
        this.spinnerService.deactivateSpinner();
      });
    }
  }

  public fileDownload(file: FileInfo): void {
    this.spinnerService.activateSpinner();
    this.fileService.downloadDocument(file).subscribe(() => {
      this.spinnerService.deactivateSpinner();
      this.notificationService.displayNotificationToast('common.download-will-begin-shortly', NotificationMessageType.SUCCESS);
    }, (error) => {
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
      this.spinnerService.deactivateSpinner();
    });
  }

  public fileDelete(file: FileInfo): void {
    this.spinnerService.activateSpinner();
    const targetedEndpoint = file.id.split('/')[0].split('-file')[0];
    this.fileService.deleteFile(Utility.getObjectId(file.id), targetedEndpoint).subscribe(() => {
      this.tableRefreshService.onTableRefresh('fileTable');
      this.notificationService.displayNotificationToast('common.file-deleted-successfully', NotificationMessageType.SUCCESS);
    }, (error) => {
      this.spinnerService.deactivateSpinner();
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
  }

}

