import {Injectable} from '@angular/core';
import {
  DryerModelCtrl,
  FurnaceModelCtrl,
  MachineModelCtrl,
  MixingMachineModelCtrl,
  ProductionDeviceModelCtrl
} from '../../../core/sdk/controllers-productiondevices';
import {DryerModel, FurnaceModel, MachineModel, MixingMachineModel, ProductionDeviceModel} from '../../../core/sdk/model-productiondevices';
import {Observable} from 'rxjs';
import {Utility} from '../../utilities/utility';

@Injectable({
  providedIn: 'root'
})
export class ProductionDeviceModelService {

  constructor(private furnaceModelCtrl: FurnaceModelCtrl,
              private dryerModelCtrl: DryerModelCtrl,
              private machineModelCtrl: MachineModelCtrl,
              private mixingMachineModelCtrl: MixingMachineModelCtrl,
              private productionDeviceModelCtrl: ProductionDeviceModelCtrl) {
  }

  public getNewFurnaceModel(): FurnaceModel {
    return {
      id: 'furnace-model/' + Utility.getUUID(),
      workspaceGroupList: [],
      chamberDepth: 0,
      chamberHeight: 0,
      chamberWidth: 0,
      costCenter: 0,
      deleted: false,
      entityVersion: 0,
      furnaceInstanceList: [],
      isDeleted: false,
      maximumLoad: 0,
      maximumTemperature: 0,
      name: '',
      power: 0,
      timestamp: Date.now(),
      type: null,
      version: 0
    };
  }

  public getNewDryerModel(): DryerModel {
    return {
      id: 'dryer-model/' + Utility.getUUID(),
      workspaceGroupList: [],
      chamberDepth: 0,
      chamberHeight: 0,
      chamberWidth: 0,
      costCenter: 0,
      deleted: false,
      dryerInstanceList: [],
      entityVersion: 0,
      isDeleted: false,
      maximumLoad: 0,
      maximumTemperature: 0,
      name: '',
      timestamp: Date.now(),
      version: 0
    };
  }

  public getNewMachineModel(): MachineModel {
    return {
      id: 'machine-model/' + Utility.getUUID(),
      workspaceGroupList: [],
      costCenter: 0,
      deleted: false,
      entityVersion: 0,
      isDeleted: false,
      machineInstanceList: [],
      name: '',
      timestamp: Date.now(),
      type: null,
      version: 0
    };
  }

  public getNewMixingMachineModel(): MixingMachineModel {
    return {
      id: 'mixing-machine-model/' + Utility.getUUID(),
      workspaceGroupList: [],
      costCenter: 0,
      deleted: false,
      entityVersion: 0,
      isDeleted: false,
      minimalLoad: 0,
      mixingMachineInstanceList: [],
      name: '',
      timestamp: Date.now(),
      version: 0
    };
  }

  public createProductionDeviceModel(productionDeviceModel: ProductionDeviceModel): Observable<ProductionDeviceModel> {
    return this.productionDeviceModelCtrl.create(productionDeviceModel);
  }

  public updateProductionDeviceModel(productionDeviceModel: ProductionDeviceModel): Observable<ProductionDeviceModel> {
    return this.productionDeviceModelCtrl.update(productionDeviceModel);
  }


}
