import {Injectable} from '@angular/core';
import {TaxRate} from '../../core/sdk/model-tax';
import {TaxRateCtrl} from '../../core/sdk/controllers-tax';
import {Utility} from '../utilities/utility';
import {SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {Country} from '../../core/sdk/model-address';
import {NotificationMessageType} from '../enums/NotificationMessageType';
import {NotificationService} from './notification.service';
import {SpinnerService} from './spinner.service';
import {Router} from '@angular/router';
import {PTableControlService} from './p-table-control.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TaxRateService {

  public taxRateData: TaxRate[];

  constructor(private taxRateCtrl: TaxRateCtrl,
              private notificationService: NotificationService,
              private spinnerService: SpinnerService,
              private router: Router,
              private pTableControlService: PTableControlService) {
  }

  public getNewEmptyTaxRate(): TaxRate {
    return {
      id: 'tax-rate/' + Utility.getUUID(),
      deleted: false,
      entityVersion: 0,
      percentage: 0,
      type: null,
      countryList: [],
      isDeleted: false,
      timestamp: 0,
      version: 0
    };
  }

  public setTaxRateData(data: TaxRate[]): void {
    this.taxRateData = data;
  }

  public getTaxRateData(): TaxRate[] {
    return this.taxRateData;
  }

  public getTaxRates(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number)
    : Observable<TaxRate[]> {
    return this.taxRateCtrl.getTaxRates(searchCriteria, pageNumber, pageSize);
  }

  public countTaxRates(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.taxRateCtrl.countTaxRates(searchCriteria);
  }

  public createTaxRate(entity: TaxRate): Observable<TaxRate> {
    return this.taxRateCtrl.createTaxRate(entity);
  }

  public updateTaxRate(taxRate: TaxRate): Observable<TaxRate> {
    return this.taxRateCtrl.updateTaxRate(taxRate);
  }

  public unassignCountryFromTaxRate(countryId: string, taxRateId: string): Observable<TaxRate> {
    return this.taxRateCtrl.unassignCountryFromTaxRate(Utility.getObjectId(countryId), Utility.getObjectId(taxRateId));
  }

  public handleAssigningCountriesTaxRate(countriesIds: string[], taxRate: TaxRate, message: string,
                                         returnToPreviousLocation?: boolean): void {
    this.spinnerService.activateSpinner();
    this.assignCountriesToTaxRate(countriesIds, taxRate.id).subscribe({
      next: (response: TaxRate) => {
        Utility.updateObjectInData(response, this.getTaxRateData());
        this.notificationService.displayNotificationToast('admin.' + message, NotificationMessageType.SUCCESS);
        this.spinnerService.deactivateSpinner();
        if (returnToPreviousLocation) {
          this.returnToPreviousLocation(taxRate);
        }
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public assignCountriesToTaxRate(countryIDsList: string[], taxRateId: string): Observable<TaxRate> {
    return this.taxRateCtrl.assignCountriesToTaxRate(countryIDsList, Utility.getObjectId(taxRateId));
  }

  public returnToPreviousLocation(taxRate: TaxRate): void {
    this.pTableControlService.pushExpandedRowToMap('taxRateTable', taxRate.id);
    this.pTableControlService.setOneTimeSelectedTab('taxRateTable', 'admin.details');
    this.router.navigate(['bh/admin/tax-management']);
  }

  public getTaxRateCountriesAssigned(taxRateId: string, pageNumber: number, pageSize: number): Observable<Country[]> {
    return this.taxRateCtrl.getTaxRateCountriesAssigned(Utility.getObjectId(taxRateId), pageNumber, pageSize);
  }

  public countTaxRateCountriesAssigned(taxRateId: string): Observable<number> {
    return this.taxRateCtrl.countTaxRateCountriesAssigned(Utility.getObjectId(taxRateId));
  }

  public getTaxRate(id: string): Observable<TaxRate> {
    return this.taxRateCtrl.getObject(Utility.getObjectId(id));
  }

}
