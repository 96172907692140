import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductionSchemaStep, ProductionSchemaTemplate} from '../../../../core/sdk/model-productionschema';
import {FiringSchemaStep, FiringSchemaTemplate} from '../../../../core/sdk/model-firingschema';
import {SchemaService} from '../../../services/schema.service';
import {Utility} from '../../../utilities/utility';
import {TranslateService} from '../../../../core/translations/translate.service';

@Component({
  selector: 'app-schema-template-overview-tile',
  templateUrl: './schema-template-overview-tile.component.html',
  styles: []
})
export class SchemaTemplateOverviewTileComponent implements OnInit {

  @Input() template: ProductionSchemaTemplate | FiringSchemaTemplate;
  @Output() editTemplateEmitter = new EventEmitter<ProductionSchemaTemplate | FiringSchemaTemplate>();
  @Output() removeTemplateEmitter = new EventEmitter<ProductionSchemaTemplate | FiringSchemaTemplate>();
  @Output() cloneTemplateEmitter = new EventEmitter<ProductionSchemaTemplate | FiringSchemaTemplate>();

  editFlag = false;

  constructor(public schemaService: SchemaService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
  }

  editTemplate(): void {
    this.editTemplateEmitter.emit(this.template);
  }

  removeProductionSchemaTemplate(): void {
    this.removeTemplateEmitter.emit(this.template);
  }

  getTemplateSteps(template: ProductionSchemaTemplate | FiringSchemaTemplate): ProductionSchemaStep[] | FiringSchemaStep[] {
    return this.schemaService.getTemplateSteps(template);
  }

  cloneTemplate(): void {
    this.cloneTemplateEmitter.emit(this.template);
  }

  transformDurationObjectToString(duration: { hours: number, minutes: number, seconds: number, days: number }): string {
    return Utility.transformDurationObjectToUserFriendlyString(duration, this.translateService);
  }

}
