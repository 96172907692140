import {Injectable} from '@angular/core';
import {FiringSchemaCtrl, FiringSchemaStepCtrl, FiringSchemaTemplateCtrl} from '../../../core/sdk/controllers-firingschema';
import {Observable} from 'rxjs';
import {FiringSchema, FiringSchemaStep, FiringSchemaTemplate} from '../../../core/sdk/model-firingschema';
import {Utility} from '../../utilities/utility';
import {FurnaceModel} from '../../../core/sdk/model-productiondevices';

@Injectable({
  providedIn: 'root'
})
export class FiringSchemaService {

  constructor(private firingSchemaCtrl: FiringSchemaCtrl,
              private firingSchemaTemplateCtrl: FiringSchemaTemplateCtrl,
              private firingSchemaStepCtrl: FiringSchemaStepCtrl) {
  }

  // FIRING SCHEMA

  getEmptyFiringSchema(furnaceModel: FurnaceModel): FiringSchema {
    return {
      id: 'firing-schema/' + Utility.getUUID(),
      name: '',
      furnaceModel,
      firingSchemaStepList: [],
      deleted: false,
      isDeleted: false,
      timestamp: undefined,
      version: 0,
      entityVersion: 0
    };
  }

  updateFiringSchema(firingSchema: FiringSchema): Observable<FiringSchema> {
    return this.firingSchemaCtrl.updateObject(firingSchema);
  }

  createFiringSchema(firingSchema: FiringSchema): Observable<FiringSchema> {
    return this.firingSchemaCtrl.createObject(firingSchema);
  }

  getFiringSchemaForFurnaceModel(furnaceModelID: string): Observable<FiringSchema[]> {
    return this.firingSchemaCtrl.getFiringSchemasForFurnaceModel(Utility.getObjectId(furnaceModelID));
  }

  deleteFiringSchema(firingSchemaID: string): Observable<void> {
    return this.firingSchemaCtrl.deleteObject(Utility.getObjectId(firingSchemaID));
  }


  // FIRING SCHEMA STEPS

  getEmptyFiringSchemaStep(): FiringSchemaStep {
    return {
      id: 'firing-schema-step/' + Utility.getUUID(),
      index: 0,
      startTemperature: 0,
      stepDuration: Utility.convertSecondsToDurationObject(0),
      targetTemperature: 0,
      temperatureFluctuation: 0,
      description: '',
      deleted: false,
      isDeleted: false,
      timestamp: Date.now(),
      entityVersion: 0,
      version: 0
    };
  }

  updateFiringSchemaStep(firingSchemaStep: FiringSchemaStep): Observable<FiringSchemaStep> {
    return this.firingSchemaStepCtrl.updateObject(firingSchemaStep);
  }

  // FIRING SCHEMA TEMPLATES

  getEmptyFiringSchemaTemplate(): FiringSchemaTemplate {
    return {
      id: 'firing-schema-template/' + Utility.getUUID(),
      name: '',
      description: '',
      firingSchemaStepList: [],
      isDeleted: false,
      deleted: false,
      timestamp: Date.now(),
      entityVersion: 0,
      version: 0
    };
  }

  importFiringSchemaTemplate(firingSchemaTemplateId: string): Observable<FiringSchemaStep[]> {
    return this.firingSchemaTemplateCtrl.getDuplicatedTemplateFiringSchemaStepList(Utility.getObjectId(firingSchemaTemplateId));
  }

  getAllFiringSchemaTemplatesId(): Observable<FiringSchemaTemplate[]> {
    return this.firingSchemaTemplateCtrl.getAllTemplates();
  }

  getAllFiringSchemaTemplates(): Observable<FiringSchemaTemplate[]> {
    return this.firingSchemaTemplateCtrl.getAllTemplates();
  }

  createFiringSchemaTemplate(firingSchemaTemplate: FiringSchemaTemplate): Observable<FiringSchemaTemplate> {
    return this.firingSchemaTemplateCtrl.createObject(firingSchemaTemplate);
  }

  updateFiringSchemaTemplate(firingSchemaTemplate: FiringSchemaTemplate): Observable<FiringSchemaTemplate> {
    return this.firingSchemaTemplateCtrl.updateObject(firingSchemaTemplate);
  }

  removeFiringSchemaTemplate(firingSchemaTemplateId: string): Observable<void> {
    return this.firingSchemaTemplateCtrl.deleteObject(Utility.getObjectId(Utility.getObjectId(firingSchemaTemplateId)));
  }

  getCloneOfFiringSchemaTemplate(firingSchemaTemplateId: string): Observable<FiringSchemaTemplate> {
    return this.firingSchemaTemplateCtrl.createDuplicateFromFiringSchemaTemplate(Utility.getObjectId(firingSchemaTemplateId));
  }

}
