import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProductType} from '../../../core/sdk/model-producttype';
import {
  MoldingSchemaStep,
  ProductionSchema,
  ProductionSchemaStep,
  ProductionSchemaTemplate
} from '../../../core/sdk/model-productionschema';
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification.service';
import {CrossFunctionalService} from '../../services/cross-functional.service';
import {PTableControlService} from '../../services/p-table-control.service';
import {SpinnerService} from '../../services/spinner.service';
import {Utility} from '../../utilities/utility';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {FurnaceModel} from '../../../core/sdk/model-productiondevices';
import {FiringSchema, FiringSchemaStep, FiringSchemaTemplate} from '../../../core/sdk/model-firingschema';
import {SchemaService} from '../../services/schema.service';
import {TranslateService} from '../../../core/translations/translate.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstanceValues} from '../../constance-values/constance-values';
import {ProductionSchemaStepType} from '../../../core/sdk/enums-types';
import {Subscription} from 'rxjs';
import {UserDecisionModalService} from '../../services/user-decision-modal.service';
import {PlaceHolderUserModalDecisionDirective} from '../../directives/place-holder-user-modal-decision.directive';
import {ProductTypeService} from '../../services/product-type.service';
import {MoldType} from '../../../core/sdk/model-mold';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-full-schema-edition',
  templateUrl: './full-schema-edition.component.html'
})
export class FullSchemaEditionComponent implements OnInit, OnDestroy, AfterViewInit {

  public formGroup: FormGroup;
  public importTemplateFlag = false;
  public templatesOptions: ProductionSchemaTemplate[] | FiringSchemaTemplate[];
  public selectedTemplate: ProductionSchemaTemplate | FiringSchemaTemplate;
  public shouldNameBeDisplayed = false;
  public shouldDescriptionBeDisplayed = false;
  public currentSchema: ProductionSchema | FiringSchema | FiringSchemaTemplate | ProductionSchemaTemplate;
  public closeAllStepEditionEmitter: EventEmitter<void> = new EventEmitter<void>();

  private currentSchemaOwner: ProductType | FurnaceModel;
  private totalStepsDuration = {days: 0, hours: 0, minutes: 0, seconds: 0};
  private currentStep: ProductionSchemaStep | FiringSchemaStep;
  private fullSchemaSubscription: Subscription;

  private readonly modalTitle = 'schemas.do-you-want-to-change-assigned-mold-type';
  private readonly modalMessage
    = 'schemas.saving-the-schema-will-not-be-possible-without-assigning-new-mold-type-and-further-task-creation-will-be-blocked';

  @ViewChild(PlaceHolderUserModalDecisionDirective, {static: false}) public modalDecisionDirective: PlaceHolderUserModalDecisionDirective;

  constructor(private router: Router,
              private notificationService: NotificationService,
              private schemaService: SchemaService,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private crossFunctionalService: CrossFunctionalService,
              private pTableControlService: PTableControlService,
              private spinnerService: SpinnerService,
              private userDecisionModalService: UserDecisionModalService,
              private productTypeService: ProductTypeService) {
    if (!this.crossFunctionalService.isCrossFunctionalActive('passedProductionSchema')
      && (!this.crossFunctionalService.isCrossFunctionalActive('passedFiringSchema'))) {
      this.returnToManagementIfSchemaNotAvailable();
    }
  }

  public ngOnInit(): void {
    if (this.crossFunctionalService.isCrossFunctionalActive('passedProductionSchema')) {
      this.currentSchema = this.crossFunctionalService.getGatheredData('passedProductionSchema');
      this.initFormGroup();
      if (!this.isTemplate(this.currentSchema.id)) {
        this.currentSchemaOwner = this.crossFunctionalService.getGatheredData('passedProductType');
      } else {
        this.shouldNameBeDisplayed = true;
        this.shouldDescriptionBeDisplayed = true;
        this.checkForNameAndDescriptionValues();
      }
      if (!this.crossFunctionalService.isCrossFunctionalActive('currentlyEditedStepId')) {
        this.schemaService.assignSchemaSteps((this.currentSchema as ProductionSchema)?.productionSchemaStepList);
      }
    } else if (this.crossFunctionalService.isCrossFunctionalActive('passedFiringSchema')) {
      this.currentSchema = this.crossFunctionalService.getGatheredData('passedFiringSchema');
      this.crossFunctionalService.flushData('passedFiringSchema');
      this.shouldNameBeDisplayed = true;
      this.initFormGroup();
      this.nameControl.patchValue((this.currentSchema as FiringSchemaTemplate | FiringSchema).name ?? ' ');
      if (!this.isTemplate(this.currentSchema.id)) {
        this.currentSchemaOwner = this.crossFunctionalService.getGatheredData('passedFurnaceModel');
        this.crossFunctionalService.flushData('passedFurnaceModel');
      } else {
        this.shouldDescriptionBeDisplayed = true;
        this.checkForNameAndDescriptionValues();
      }
      if (!this.crossFunctionalService.isCrossFunctionalActive('currentlyEditedStepId')) {
        this.schemaService.assignSchemaSteps((this.currentSchema as FiringSchema)?.firingSchemaStepList);
      }
    }
    this.initTotalDuration();
    this.spinnerService.activateSpinner();
  }

  public ngAfterViewInit(): void {
    this.fetchTemplates();
  }

  public ngOnDestroy(): void {
    if (!this.crossFunctionalService.isCrossFunctionalActive('currentlyEditedStepId')) {
      this.crossFunctionalService.flushData('passedProductionSchema');
      this.crossFunctionalService.flushData('passedProductType');
      this.crossFunctionalService.flushData('nameDescriptionForTemplate');
    } else if (this.isTemplate(this.currentSchema.id)) {
      this.crossFunctionalService.setGatheredData('nameDescriptionForTemplate', {
        name: this.nameControl.value,
        description: this.descriptionControl.value
      });
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({});
    if (!this.isProductionSchema(this.currentSchema.id) || this.isTemplate(this.currentSchema.id)) {
      this.attachNameControl();
    }
    if (this.isTemplate(this.currentSchema.id)) {
      this.attachDescriptionControl();
    }
  }

  private attachNameControl(): void {
    if ('name' in this.currentSchema) {
      this.formGroup.addControl('name', this.formBuilder.control(this.currentSchema.name,
        [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]));
    }
  }

  private attachDescriptionControl(): void {
    if ('description' in this.currentSchema) {
      this.formGroup.addControl('description', this.formBuilder.control(this.currentSchema.description,
        [Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]));
    }
  }

  private checkForNameAndDescriptionValues(): void {
    if (this.crossFunctionalService.isCrossFunctionalActive('nameDescriptionForTemplate')) {
      const nameDescriptionForTemplates = this.crossFunctionalService.getGatheredData('nameDescriptionForTemplate');
      this.nameControl.patchValue(nameDescriptionForTemplates.name);
      (this.currentSchema as ProductionSchemaTemplate).name = nameDescriptionForTemplates.name;
      this.descriptionControl.patchValue(nameDescriptionForTemplates.description);
      (this.currentSchema as ProductionSchemaTemplate).description = nameDescriptionForTemplates.description;
    } else {
      this.nameControl.patchValue((this.currentSchema as ProductionSchemaTemplate).name ?? ' ');
      this.descriptionControl.patchValue((this.currentSchema as ProductionSchemaTemplate).description ?? ' ');
    }
  }

  private returnToManagementIfSchemaNotAvailable(): void {
    if (this.router.url.split('/')[2] === 'products') {
      this.router.navigate(['bh/products/product-type-management']);
    } else {
      this.router.navigate(['bh/production/furnaces-management']);
    }
  }


  private fetchTemplates(): void {
    this.schemaService.getAllTemplatesId(this.currentSchema.id).subscribe(templates => {
      this.templatesOptions = templates;
      this.spinnerService.deactivateSpinner();
      this.templatesOptions.sort((a, b) => Utility.compareNumbers(a.name, b.name));
      this.selectedTemplate = this.templatesOptions[0];
    });
  }

  private initTotalDuration(): void {
    this.totalStepsDuration = this.getTotalDurationOfSteps();
  }

  public returnPreviousLocation(): void {
    if (this.isTemplate(this.currentSchema.id)) {
      this.returnToTemplates();
    } else {
      this.returnToSchema();
    }
  }

  private returnToSchema(): void {
    if (this.isProductionSchema(this.currentSchema.id)) {
      this.returnToProductionSchema();
    } else {
      this.returnToFiringSchema();
    }
  }


  private returnToProductionSchema(): void {
    this.pTableControlService.pushExpandedRowToMap('productTypeTable', this.currentSchemaOwner?.id);
    this.pTableControlService.setOneTimeSelectedTab('productTypeTable', 'products.production-schema');
    this.router.navigate(['bh/products/product-type-management']);
  }

  private returnToFiringSchema(): void {
    this.pTableControlService.pushExpandedRowToMap('furnaceModelTable', this.currentSchemaOwner?.id);
    this.pTableControlService.setOneTimeSelectedTab('furnaceModelTable', this.translateService.translate('production.firing-schemas'));
    this.router.navigate(['bh/production/furnaces-management']);
  }

  private returnToTemplates(): void {
    if (this.isProductionSchema(this.currentSchema.id)) {
      this.returnToProductionSchemaTemplates();
    } else {
      this.returnToFiringSchemaTemplates();
    }
  }

  private returnToProductionSchemaTemplates(): void {
    this.router.navigate(['bh/products/production-template-schemas']);
  }

  private returnToFiringSchemaTemplates(): void {
    this.router.navigate(['bh/production/firing-schema-templates']);
  }

  public toggleImportFlag(): void {
    this.importTemplateFlag = !this.importTemplateFlag;
  }


  public clearAllStep(): void {
    this.schemaService.clearSchemaStepsFromData();
    this.initTotalDuration();
  }

  private getTotalDurationOfSteps(): { days: number, hours: number, minutes: number, seconds: number } {
    const seconds = this.schemaService.getAllStepsTotalDuration(this.schemaService.getSchemaSteps());
    return Utility.convertSecondsToDurationObject(seconds ?? 0);
  }

  public transformDurationToString(): string {
    return Utility.transformDurationObjectToUserFriendlyString(this.totalStepsDuration, this.translateService);
  }


  private getCurrentMoldingSteps(): MoldingSchemaStep[] {
    return this.isProductionSchema(this.currentSchema.id) ? (this.schemaService.getSchemaSteps() as ProductionSchemaStep[])
      .filter(step => step.type === ProductionSchemaStepType.MOLDING_SCHEMA_STEP) as MoldingSchemaStep[] : [];
  }

  private allMoldingStepsHasTheSameMoldType(): boolean {
    const moldingSteps = this.getCurrentMoldingSteps();
    return !moldingSteps.isEmpty() && moldingSteps.every(step => step.moldType.id === moldingSteps[0].moldType.id);
  }

  private isProductTypeWithoutAssignedMoldType(): boolean {
    return !this.isTemplate(this.currentSchema.id) && (this.currentSchemaOwner as ProductType).moldType == null;
  }

  private changeAssignedMoldTypeToProductType(moldType: MoldType, productTypeId: string): void {
    this.productTypeService.changeAssignedMoldType(moldType, productTypeId).subscribe({
      next: (response: ProductType) => {
        Utility.updateObjectInData(response, this.productTypeService.data);
        this.notificationService.displayNotificationToast('schemas.mold-type-assigned-successfully',
          NotificationMessageType.SUCCESS);
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }


  public onSubmit(): void {
    if (!this.allMoldingStepsHasTheSameMoldType() && this.getCurrentMoldingSteps().length > 0) {
      this.notificationService.displayNotificationToast('schemas.there-is-some-molding-schema-steps-with-different-mold-type',
        NotificationMessageType.WARNING);
    } else {
      this.fullSchemaSubscription = this.userDecisionModalService.performFullSchemaAction$.subscribe((performAction: boolean) => {
        if (performAction) {
          this.handleProcessingSchema();
          if ((this.isProductTypeWithoutAssignedMoldType()
              && this.getCurrentMoldingSteps().length > 0
              && this.allMoldingStepsHasTheSameMoldType())
            || (this.conditionsAreMetToHandleSchemaWithModal())) {
            this.changeAssignedMoldTypeToProductType(this.getCurrentMoldingSteps()[0].moldType,
              (this.currentSchemaOwner as ProductType).id);
          }
        }
        this.fullSchemaSubscription.unsubscribe();
      });

      if (this.conditionsAreMetToHandleSchemaWithModal()) {
        this.userDecisionModalService.viewContainerReference = this.modalDecisionDirective.viewContainerRef;
        this.userDecisionModalService.openUserDecisionModal(this.modalTitle, this.modalMessage);
      } else {
        this.userDecisionModalService.handlePerformingUserDecisionAction(true);
      }
    }

  }

  private conditionsAreMetToHandleSchemaWithModal(): boolean {
    return !this.isProductTypeWithoutAssignedMoldType()
      && this.getCurrentMoldingSteps().length > 0
      && this.allMoldingStepsHasTheSameMoldType()
      && this.getCurrentMoldingSteps()[0].moldType.id !== (this.currentSchemaOwner as ProductType).moldType.id;
  }

  private handleProcessingSchema(): void {
    this.spinnerService.activateSpinner();
    this.closeAllStepEditionEmitter.emit();
    if (this.isProductionSchema(this.currentSchema.id)) {
      (this.currentSchema as ProductionSchema).productionSchemaStepList = this.schemaService.getSchemaSteps() as ProductionSchemaStep[];
    } else {
      (this.currentSchema as FiringSchema).name = this.nameControl.value;
      (this.currentSchema as FiringSchema).firingSchemaStepList = this.schemaService.getSchemaSteps() as FiringSchemaStep[];
    }
    this.schemaService.updateIndexAndPropertiesInSchemaSteps(
      this.isProductionSchema(this.currentSchema.id) && !this.isTemplate(this.currentSchema.id)
        ? this.currentSchema as ProductionSchema : null);

    if (this.isTemplate(this.currentSchema.id)) {
      (this.currentSchema as ProductionSchemaTemplate | FiringSchemaTemplate).description = this.descriptionControl.value;
      (this.currentSchema as ProductionSchemaTemplate | FiringSchemaTemplate).name = this.nameControl.value;
      this.schemaService.updateIndexAndPropertiesInSchemaSteps();
      this.schemaService.updateSchemaTemplate(this.currentSchema as ProductionSchemaTemplate | FiringSchemaTemplate).subscribe(() => {
        this.notificationService.displayNotificationToast('schemas.template-updated', NotificationMessageType.SUCCESS);
        this.returnToTemplates();
      }, (error) => {
        this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService);
      });
    } else {
      this.schemaService.updateSchema(this.currentSchema as ProductionSchema | FiringSchema).subscribe(() => {
        this.notificationService.displayNotificationToast('schemas.schema-updated', NotificationMessageType.SUCCESS);
        if (this.isProductionSchema(this.currentSchema.id)) {
          const deletedStepsIdList = this.schemaService.getDeletedStepIdArray();
          if (deletedStepsIdList.length > 0) {
            this.schemaService.deleteRemovedSteps(deletedStepsIdList).subscribe(() => {
              this.returnToSchema();
            }, (error) => {
              this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService);
            });

          } else {
            this.returnToSchema();
          }
        } else {
          this.returnToSchema();
        }
      }, (error) => {
        this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService);
      });
    }
  }


  public importSelectedTemplate(templateId: string): void {
    this.schemaService.importSchemaTemplate(templateId).subscribe((response: ProductionSchemaStep[] | FiringSchemaStep[]) => {
      response.sort((a, b) => Utility.compareNumbers(a.index, b.index));
      this.schemaService.joinStepsToData(response);
      this.notificationService.displayNotificationToast('schemas.template-imported-successfully',
        NotificationMessageType.SUCCESS);
    }, (error) => {
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
    this.toggleImportFlag();
  }

  public createNewStep(): void {
    this.currentStep = this.schemaService.getEmptySchemaStep(this.currentSchema.id);
    this.currentStep.index = this.schemaService.getSchemaSteps() ? this.schemaService.getSchemaSteps().length + 1 : 1;
    this.crossFunctionalService.setGatheredData('currentlyEditedStepId', this.currentStep.id);
    this.schemaService.pushNewStepToData(this.currentStep);
  }

  private isProductionSchema(schemaId: string): boolean {
    return schemaId.includes('production');
  }

  private isTemplate(templateId: string): boolean {
    return templateId.includes('template');
  }

  public get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  public get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

  public checkIfStepsAreAvailable(steps: FiringSchemaStep[] | ProductionSchemaStep[]): boolean {
    return steps.isEmpty();
  }

  public showPlaceHolder(): boolean {
    if (this.isProductionSchema(this.currentSchema.id)) {
      return this.checkIfStepsAreAvailable((this.currentSchema as ProductionSchema).productionSchemaStepList);
    } else {
      return this.checkIfStepsAreAvailable((this.currentSchema as FiringSchema).firingSchemaStepList);
    }
  }

}
