import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LoginCtrl, PersonCtrl} from '../sdk/bighero-controllers';
import {Person} from '../sdk/bighero-model';
import {SessionService} from '../../shared/services/session.service';
import {TranslateService} from '../translations/translate.service';
import {Utility} from '../../shared/utilities/utility';
import {PersonLogin} from '../sdk/model-dto';
import {NotificationService} from '../../shared/services/notification.service';
import {SpinnerService} from '../../shared/services/spinner.service';
import {Language} from '../sdk/model-translations';
import {FunctionalityService} from '../../shared/services/functionality.service';
import {PersonFile} from '../sdk/model-files';
import {FunctionalityGroup} from '../sdk/model-enums';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authToken: string;
  private loggedUser: Person;

  constructor(private router: Router,
              private loginCtrl: LoginCtrl,
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private personCtrl: PersonCtrl,
              private spinnerService: SpinnerService,
              private functionalityService: FunctionalityService,
              private sessionService: SessionService) {
  }

  public loginWithRedirect(email: string, password: string, url: string): void {
    this.spinnerService.activateSpinner();
    this.sessionService.clearSession();
    this.loginCtrl.login(this.getPersonLogin(email, password)).subscribe((response) => {
        if (response.token) {
          this.setAuthToken(response.token);
          this.setLoggedPerson(response.person);
          this.setLoggedPersonLanguage(response.defaultUserLanguage);
          this.translateService.loadLanguages();
          this.functionalityService.setFunctionalityMap(response.functionalityMap);
          this.functionalityService.setFunctionalityGroups(response.mainFunctionalities);
          this.spinnerService.deactivateSpinner();
          this.navigate(url);
        }
      }, (err) => {
        this.spinnerService.deactivateSpinner();
        Utility.showViolationsIfOccurs(err, this.notificationService);
      }
    );
  }

  public sessionLogin(): void {
    this.saveSessionInApp();
    this.translateService.translationsLoaded
      .subscribe(() => {
        this.functionalitiesInit();
      });
  }

  public functionalitiesInit(): void {
  }

  public navigate(url: string): void {
    url = this.getUrlToMobileFunctionalityIfPresent(url);
    if (url !== '') {
      this.router.navigateByUrl(url);
    } else {
      this.router.navigate(['/bh']);
    }
  }

  private getUrlToMobileFunctionalityIfPresent(url: string): string {
    if (this.functionalityService.getUserFunctionalityGroups()
      .find(functionalityGroup => functionalityGroup === FunctionalityGroup.MOBILE)) {
      return '/bh/mobile';
    }
    return url;
  }

  public isAuthenticated(): boolean {
    const token = this.sessionService.getToken();
    const person = this.sessionService.getPerson();
    return token !== '' && !!person.id;
  }

  public logout(): void {
    this.authToken = '';
    this.sessionService.clearSession();
    this.router.navigate(['/login']);
  }

  public saveSessionInApp(): void {
    this.authToken = this.sessionService.getToken();
    this.loggedUser = this.sessionService.getPerson();
  }

  public setAuthToken(token: string): void {
    this.sessionService.setToken(token);
    this.authToken = token;
  }

  public setLoggedPerson(person: Person): void {
    this.sessionService.setPerson(person);
    this.loggedUser = person;
  }

  public setLoggedPersonLanguage(language: Language): void {
    this.translateService.currentLanguage = language;
  }

  public setAvatarForLoggedUser(file: PersonFile): void {
    this.loggedUser.avatar = file;
    this.setLoggedPerson(this.loggedUser);
  }

  public getCurrentAuthToken(): string {
    return this.authToken ? this.authToken : this.sessionService.getToken();
  }

  public getPersonLogin(email: string, pwd: string): PersonLogin {
    return {
      password: pwd,
      email
    };
  }

  public getCurrentUser(): Person {
    return this.loggedUser ? this.loggedUser : this.sessionService.getPerson();
  }

  public doUpdateDefaultLanguageForPerson(): void {
    if (this.getCurrentUser()) {
      this.personCtrl.updateDefaultLanguageForPerson(this.translateService.currentLanguage.locale).subscribe(
        data => {
          this.setLoggedPerson(data);
        });
    }
  }


}
