import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastStringAfterDot'
})
export class LastStringAfterDotPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const strArr = value.split('.');
    return strArr[strArr?.length - 1];
  }

}
