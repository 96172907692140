
export enum AddressType {
DELIVERY_ADDRESS,
BILLING_ADDRESS,
MAIN_ADDRESS
}

export enum BatchHistoryType {
}

export enum FileType {
DOCUMENT,
IMAGE,
INVOICE,
INVENTORY_REPORT
}

export enum FurnaceModelType {
ELECTRIC,
GAS
}

export enum InvoiceApplicableTaxType {
USE_CLIENT_MAIN_ADDRESS_COUNTRY_TAX_RATE,
USE_CLIENT_SHIPPING_ADDRESS_COUNTRY_TAX_RATE,
USE_GERMAN_PRODUCTS_TAX_RATE
}

export enum InvoiceInfoLineType {
FOOTER_LINE,
MAIN_BODY_LINE_COMMON,
MAIN_BODY_LINE_UNIQUE
}

export enum MoldPartTypeEnum {
FRAME,
INLAY
}

export enum OrderInvoiceType {
PROFORMA,
FINAL,
DEPOSIT
}

export enum ProductionDeviceModelType {
DRYER_MODEL,
FURNACE_MODEL,
MACHINE_MODEL,
MIXING_MACHINE_MODEL
}

export enum ProductionLogActionType {
ORDER_MARKED_FOR_PRODUCTION,
PRODUCTION_BOOKED,
WORKER_TASK_STARTED,
NEW_BATCH_CREATED,
NEW_PRODUCTS_PRODUCED,
PRODUCED_PRODUCTS_HANDED_FOR_STORAGE
}

export enum ProductionSchemaStepType {
PRODUCTION_SCHEMA_STEP,
BURNING_SCHEMA_STEP,
MOLDING_SCHEMA_STEP,
DRYING_SCHEMA_STEP,
POST_PROCESSING_SCHEMA_STEP
}

export enum SizeChangeType {
GROW,
SHRINK,
NO_CHANGE
}

export enum StorageAreaType {
SHELF,
GROUND
}

export enum StoringUnitTypeEnum {
PALETTE,
BOX
}

export enum SupplierArticleTypeEnum {
}

export enum SupplyOrderType {
}

export enum TaskType {
WORKER_TASK,
BURNING_TASK
}

export enum TaxRateType {
FOR_PRODUCTS,
FOR_SERVICES
}

export enum WebsocketMessageType {
CREATE_NOTIFICATION,
UPDATE_NOTIFICATION
}

export enum WorkspaceGroupType {
DryingArea,
OvenOperation,
MachineOperation,
WithoutMachine,
PostProcessingOperation
}

