<div class="global-flex global-justify__center">
  <div class="basket">
    <div class="basket__header">
      <h2>
        <i class="pi pi-shopping-cart"></i>
        {{'common.basket-is-active' | translate}}
      </h2>


      <div class="basket__header__buttons">
        <button
          class="common-button global-margin__small-left"
          (click)="turnBasketOff()"
        >{{'common.turn-basket-off' | translate}}</button>
        <button
          *ngIf="isProductStoredInstancesInBasket()"
          class="common-button global-margin__small-left"
          (click)="createPickUpProducts()"
        >{{'common.create-pick-up-products' | translate}}</button>
      </div>
    </div>
    <div
      class="basket__products"
      [ngClass]="showAllObjectsFlag ? 'global-flex-direction__column' : 'global-flex-direction__row'">
      <ng-container *ngIf="showAllObjectsFlag; then allProducts; else summary"></ng-container>
    </div>

    <ng-template #allProducts>
      <button
        class="common-button pi pi-arrow-up global-align--self-end global-margin__small-left"
        (click)="toggleShowAllObjectsFlag()"
      ></button>
      <div class="basket__products__product-list">
        <p-table
          class="main-table-header"
          responsiveLayout="scroll"
          [value]="objectsList">

          <ng-template pTemplate="header">
            <tr>
              <ng-container [ngTemplateOutlet]="tableHeaderTemplate || defaultTableHeader"></ng-container>
              <ng-template #defaultTableHeader>
                <th>{{'raw-materials.name' | translate}}</th>
              </ng-template>
              <th>{{'common.remove' | translate}}</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td [colSpan]="getCorrectColSpanValue()">
                {{'common.no-items-in-the-basket' |translate}}
              </td>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="body"
            let-basketContentObject>
            <tr>
              <ng-container
                [ngTemplateOutlet]="tableContentTemplate || defaultTableContent"
                [ngTemplateOutletContext]="{$implicit: basketContentObject}">
              </ng-container>
              <ng-template #defaultTableContent>
                <td>{{basketContentObject.name}}</td>
              </ng-template>
              <td class="global-text-align__center">
                <button
                  type="button"
                  class="p-button pi pi-trash global-margin--small-side global-background-color--red"
                  (click)="removeObject(basketContentObject)"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
    <ng-template #summary>
      <div class="basket__products__products-summary">
        <label>{{('common.you-have-selected' | translate) + ' ' + objectsList.length + ' ' + ('common.items' | translate)}}</label>
      </div>
      <button
        class="common-button pi pi-arrow-down"
        (click)="toggleShowAllObjectsFlag()"
      ></button>
    </ng-template>
  </div>
</div>
