<div
  [ngClass]="!applyAssigningWorkspaceInstanceFlag ? 'visibility--true' : 'visibility--false global-height--0'"
  class="production-device-instances">
  <div
    *ngIf="!(isAssigningActionPerformed || showWorkloadTable)"
    class="production-device-instances__header">
    <h2>{{(newDeviceFlag ? 'device-instance.create-device-instance' : 'device-instance.device-instances') | translate}}</h2>
    <div class="production-device-instances__header__buttons">
      <button
        *ngIf="!newDeviceFlag"
        type="button"
        class="common-button"
        data-cy="add-new-device-button"
        (click)="toggleNewDeviceFlag()"
      >{{'device-instance.add-new-device' | translate}}</button>
    </div>
  </div>

  <div
    *ngIf="!showWorkloadTable"
    class="production-device-instances__body">
    <ng-container *ngIf="newDeviceFlag; then createForm; else table"></ng-container>
    <ng-template
      #table>
      <p-table
        class="main-table-header"
        dataKey="id"
        editMode="row"
        data-cy="device-instances-table"
        responsiveLayout="scroll"
        [value]="getTableData()"
        [paginator]="true"
        [lazy]="true"
        [rowHover]="true"
        [(first)]="pageable.pageNumber"
        [rows]="pageable.pageSize"
        [totalRecords]="pageable.count"
        (onPage)="paginate($event)">
        <ng-template pTemplate="header">
          <tr>
            <th
              pSortableColumn="dryerName"
              (click)="sortByColumn('shorthand', true)">
              {{'device-instance.device-id' | translate}}
              <p-sortIcon field="dryerName"></p-sortIcon>
            </th>
            <th
              pSortableColumn="group"
              (click)="sortByColumn('group')">
              {{'device-instance.group' | translate}}
              <p-sortIcon field="group"></p-sortIcon>
            </th>
            <th
              pSortableColumn="status"
              (click)="sortByColumn('status')">
              {{'device-instance.status' | translate}}
              <p-sortIcon field="status"></p-sortIcon>
            </th>
            <th
              *ngIf="!hideAssignWorkspaceInstanceButtonFlag"
              pSortableColumn="workspaceInstance"
              (click)="sortByColumn('workspaceInstance')">
              {{'device-instance.workspace-instance' | translate}}
              <p-sortIcon field="workspaceInstance"></p-sortIcon>
            </th>
            <th>{{'device-instance.action' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td
              [colSpan]="!hideAssignWorkspaceInstanceButtonFlag ? 5 : 4">
              {{'common.no-records-available' | translate}}
            </td>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-editing="editing"
          let-ri="rowIndex"
          let-deviceInstance
          [formGroup]="getTableFormGroup()">
          <tr [pEditableRow]="deviceInstance">
            <td>{{deviceInstance?.shorthand}}
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <ng-container formGroupName="{{'r' + this.getRelativeRowIndex(ri).toString()}}">
                    <select
                      id="group"
                      class="global-input"
                      formControlName="group"
                      [ngClass]="getFormControl(ri, 'group').invalid ? 'global-invalid-input-box-shadow' : ''">
                      <option
                        disabled
                        [ngValue]="deviceInstance.group"
                        [label]="deviceInstance.group?.name ? deviceInstance.group.name : ('production.select-group' | translate)"
                      ></option>
                      <option
                        *ngFor="let typeObject of getAvailableGroupOptionsWithoutCurrentObject(deviceInstance?.group)"
                        [ngValue]="typeObject"
                        [label]="typeObject.name"
                      ></option>
                    </select>
                  </ng-container>
                </ng-template>
                <ng-template pTemplate="output">
                  {{(deviceInstance.group?.name) | translate}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <ng-container formGroupName="{{'r' + this.getRelativeRowIndex(ri).toString()}}">
                    <select
                      id="status"
                      class="global-input"
                      formControlName="status"
                      [ngClass]="getFormControl(ri, 'status').invalid ? 'global-invalid-input-box-shadow' : ''">
                      <option
                        *ngFor="let typeObject of getAvailableStatusOptions()"
                        [value]="typeObject.value"
                        [label]="typeObject.label | translate"
                      ></option>
                    </select>
                  </ng-container>
                </ng-template>
                <ng-template pTemplate="output">
                  {{getStatusByEnum(deviceInstance?.status)}}
                </ng-template>
              </p-cellEditor>
            </td>

            <td *ngIf="!hideAssignWorkspaceInstanceButtonFlag">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <button
                    type="button"
                    class="common-button"
                    (click)="applyAssigningWorkspaceInstance(deviceInstance)"
                  >{{(deviceInstance.workspaceInstance ? 'production.reassign-instance' : 'production.assign-instance') | translate}}
                  </button>
                </ng-template>
                <ng-template pTemplate="output">
                  {{deviceInstance.workspaceInstance ? deviceInstance.workspaceInstance.shorthand : ('production.not-assigned' | translate)}}
                </ng-template>
              </p-cellEditor>
            </td>

            <td>
              <ng-container
                *ngIf="isAssigningActionPerformed; then assignTemplate; else inLineEditTemplate">
              </ng-container>
              <ng-template #assignTemplate>
                <button
                  type="button"
                  class="common-button"
                  [ngStyle]="{'font-size': isTerminalDevice ? '1.75rem' : ''}"
                  (click)="assignToWorkspaceInstance(deviceInstance)"
                >{{'production.assign' | translate}}</button>
              </ng-template>
              <ng-template #inLineEditTemplate>
                <button
                  *ngIf="!editing"
                  pButton
                  pInitEditableRow
                  type="button"
                  class="ui-button-info global-margin__small-right"
                  icon="pi pi-pencil"
                  data-cy="edit-button"
                  [disabled]="isEditingActiveOnAnyRow()"
                  (click)="onRowEditInit(ri)"
                ></button>
                <button
                  *ngIf="!editing"
                  pButton
                  type="button"
                  class="ui-button-info global-background-color--red global-margin__small-right"
                  icon="pi pi-trash"
                  data-cy="remove-button"
                  [disabled]="isEditingActiveOnAnyRow()"
                  (click)="removeDeviceInstance(deviceInstance.id, this.getRelativeRowIndex(ri))"
                ></button>
                <button
                  *ngIf="showWorkloadButton && !editing"
                  pButton
                  type="button"
                  (click)="presentWorkloadTable(deviceInstance.id)"
                >{{'device-instance.check-full-workload-history' | translate}}</button>
                <button
                  *ngIf="editing"
                  pButton
                  pSaveEditableRow
                  type="button"
                  class="global-background-color--green global-margin__small-right"
                  icon="pi pi-check"
                  data-cy="save-button"
                  [disabled]="getRowFormControl(ri).invalid"
                  (click)="onRowEditSave(ri)"
                ></button>
                <button
                  *ngIf="editing"
                  pButton
                  pCancelEditableRow
                  type="button"
                  class="global-background-color--red"
                  icon="pi pi-times"
                  data-cy="cancel-button"
                  (click)="onRowEditCancel(ri)"
                ></button>
              </ng-template>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>

    <ng-template #createForm>
      <app-device-instances-create-form
        class="global-width--100"
        [currentDeviceModel]="currentDeviceModel"
        [availableDeviceTypeOptions]="availableDeviceTypeOptions"
        [availableStatusOptions]="availableStatusOptions"
        [availableGroupOptions]="availableGroupOptions"
        (createdInstanceEmitter)="addCreatedInstanceToData($event)">
      </app-device-instances-create-form>
    </ng-template>
  </div>

  <div *ngIf="showWorkloadTable">
    <app-workload-table
      [instanceId]="deviceInstanceId"
      (returnEmitter)="showWorkloadTable = false"
    ></app-workload-table>
  </div>
</div>


<div *ngIf="applyAssigningWorkspaceInstanceFlag">

  <ng-container
    *ngIf="currentDeviceModel.workspaceGroupList.length > 0 then workspaceGroupTable else placeholder"></ng-container>

  <ng-template #workspaceGroupTable>
    <app-workspace-groups-for-production-device-model
      [showAssignButtonForWorkspaceInstance]="true"
      [productionDeviceModelObjectForCross]="objectToAssignWorkspaceInstance"
      (chosenWorkspaceInstanceEmitter)="assignWorkspaceInstanceToDeviceInstance($event)"
      (closeComponentEmitter)="toggleApplyAssigningWorkspaceInstanceFlag()">
    </app-workspace-groups-for-production-device-model>
  </ng-template>

  <ng-template #placeholder>
    <div class="global-flex global-justify__flex-end">
      <button
        type="button"
        class="common-button"
        (click)="toggleApplyAssigningWorkspaceInstanceFlag()"
      >{{'common.return' | translate}}</button>
    </div>
    <div class="production-device-instances__dimensions-tile">
      <div>
        <span>{{'production.no-workspace-group-assigned' | translate}}</span>
      </div>
    </div>
  </ng-template>

</div>


