import {Directive, OnDestroy, OnInit} from '@angular/core';
import {BasePrimengTableDirective} from './base-primeng-table.directive';
import {BaseObject} from '../../core/sdk/bighero-model';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn} from '@angular/forms';
import {SearchCriteriaService} from '../../core/search-criteria.service';
import {TableRefreshService} from '../services/table-refresh.service';
import {SpinnerService} from '../services/spinner.service';
import {BaseSubNavbarService} from '../base-components/base-sub-navbar/base-sub-navbar.service';
import {PTableControlService} from '../services/p-table-control.service';
import {BasketService} from '../services/basket.service';
import {ExportTableDataService} from '../services/exportTableData.service';


@Directive()
export abstract class InlineEditionTableDirective<T extends BaseObject> extends BasePrimengTableDirective<T> implements OnInit, OnDestroy {

  public tableFormGroup: FormGroup;
  public formControlObjects: { name: string, validators: ValidatorFn[] }[];
  public customValuesLocations: Map<string, () => any[]>;

  public disableRelativeIndex = false;

  protected constructor(protected formBuilder: FormBuilder,
                        protected searchCriteriaService: SearchCriteriaService,
                        protected tableRefreshService?: TableRefreshService,
                        protected spinnerService?: SpinnerService,
                        protected baseSubNavbarService?: BaseSubNavbarService,
                        protected pTableControlService?: PTableControlService,
                        protected basketService?: BasketService,
                        protected exportTableDataService?: ExportTableDataService) {
    super(searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService, pTableControlService,
      basketService, exportTableDataService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setFormGroupControls();
    this.setCustomValueLocations();
    this.initFormGroupForInlineEdition();
  }

  public ngOnDestroy(): void {
    this.deactivateEditionOnAll();
    super.ngOnDestroy();
  }

  /// FORM GROUP
  public abstract setFormGroupControls(): void;

  public setCustomValueLocations(): void {
  }

  private initFormGroupForInlineEdition(): void {
    this.tableFormGroup = this.formBuilder.group({});
  }

  private addFormGroupForRow(rowIndex: number): void {
    this.tableFormGroup.addControl('r' + rowIndex.toString(), this.getNewFormGroupForRow(rowIndex));
  }

  private getNewFormGroupForRow(rowIndex: number): FormGroup {
    const formGroup = this.formBuilder.group({});
    this.formControlObjects.forEach(controlObject => {
      formGroup.addControl(controlObject.name, this.formBuilder.control(
        (this.customValuesLocations && this.customValuesLocations.has(controlObject.name)) ?
          this.customValuesLocations.get(controlObject.name)()[this.getRelativeRowIndex(rowIndex)] :
          this.getTableData()[this.getRelativeRowIndex(rowIndex, true)][controlObject.name],
        controlObject.validators));
    });
    return formGroup;
  }

  public isEditingActiveOnAnyRow(): boolean {
    return Object.keys(this.tableFormGroup.controls).length > 0;
  }

  public getTableFormGroup(): FormGroup {
    return this.tableFormGroup;
  }

  public getRowFormControl(rowIndex: number): AbstractControl {
    return this.tableFormGroup.get('r' + this.getRelativeRowIndex(rowIndex).toString());
  }

  public getFormControl(rowIndex: number, controlName: string): AbstractControl {
    return this.getRowFormControl(rowIndex).get(controlName);
  }

  protected deleteFormGroupForRow(rowIndex: number | string): void {
    this.tableFormGroup.removeControl('r' + rowIndex.toString());
  }

  /// EDITION_MODE_HANDLE

  public onRowEditInit(rowIndex: number): void {
    this.addFormGroupForRow(this.getRelativeRowIndex(rowIndex));
    this.doActionAfterInit(this.getRelativeRowIndex(rowIndex));
  }

  public onRowEditCancel(rowIndex: number): void {
    this.deleteFormGroupForRow(this.getRelativeRowIndex(rowIndex));
    this.doActionAfterCancel(this.getRelativeRowIndex(rowIndex));
  }

  public onRowEditSave(rowIndex: number): void {
    if (this.customValuesLocations) {
      this.customValuesLocations.forEach((controlCollection, controlName) => {
        if ((this.getRowFormControl(rowIndex) as FormGroup).contains(controlName)) {
          controlCollection()[this.getRelativeRowIndex(rowIndex)]
            = this.getFormControl(this.getRelativeRowIndex(rowIndex), controlName).value;
        }
      });
    }

    this.getTableData()[this.getRelativeRowIndex(rowIndex, true)] = {
      ...this.getTableData()[this.getRelativeRowIndex(rowIndex, true)],
      ...this.getRowFormControl(this.getRelativeRowIndex(rowIndex)).value
    };

    this.doActionDuringSave(this.getRelativeRowIndex(rowIndex));
    this.deleteFormGroupForRow(this.getRelativeRowIndex(rowIndex));
    this.doActionAfterSave(this.getRelativeRowIndex(rowIndex));
  }

  // to be implemented if necessary
  public doActionAfterSave(rowIndex?: number): void {
  }

  // to be implemented if necessary
  public doActionDuringSave(rowIndex?: number): void {
  }

  // to be implemented if necessary
  public doActionAfterInit(rowIndex?: number): void {

  }

  public doActionAfterCancel(rowIndex?: number): void {

  }

  public isEditModeOn(): boolean {
    return Object.keys(this.tableFormGroup.controls).length > 0;
  }

  public deactivateEditionOnAll(): void {
    Object.keys(this.tableFormGroup?.controls)?.forEach((control) => {
      this.deleteFormGroupForRow(control);
    });
  }

  public getRelativeRowIndex(currentRowIndex: number, hard?: boolean): number {
    if (hard || !this.disableRelativeIndex) {
      return currentRowIndex % this.pageable.pageSize;
    }
    return currentRowIndex;
  }

}
