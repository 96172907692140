/* tslint:disable  max-line-length  align  eofline  no-trailing-whitespace  typedef-whitespace  whitespace   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SearchCriteriaForClass} from './model-dto';
import {ProductForService, ServiceSchemaStep} from './model-productforservice';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ProductForServiceCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countProductsForService(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/product-for-service/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: ProductForService, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductForService>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductForService>();
    this.httpService.post('/api/product-for-service/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<ProductForService>  {
   const subject = new Subject<ProductForService>();
    this.httpService.get('/api/product-for-service/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductsForService(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductForService[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductForService[]>();
    this.httpService.post('/api/product-for-service/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ProductForService, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductForService>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductForService>();
    this.httpService.put('/api/product-for-service/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ServiceSchemaStepCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public deleteServiceSchemaStep(serviceSchemaStepID: string, productForServiceID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ServiceSchemaStep[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'serviceSchemaStepID', value: serviceSchemaStepID});
  
    queryParamsList.push({name: 'productForServiceID', value: productForServiceID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ServiceSchemaStep[]>();
    this.httpService.put('/api/service-schema-step/w/delete-service-schema-step', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteServiceSchemaStepList(serviceSchemaStepIDsList: string[], productForServiceID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ServiceSchemaStep[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productForServiceID', value: productForServiceID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ServiceSchemaStep[]>();
    this.httpService.put('/api/service-schema-step/w/delete-service-schema-steps', JsonScopedSerializer.stringify(serviceSchemaStepIDsList, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<ServiceSchemaStep>  {
   const subject = new Subject<ServiceSchemaStep>();
    this.httpService.get('/api/service-schema-step/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductForServiceSchemaSteps(productForServiceID: string): Observable<ServiceSchemaStep[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productForServiceID', value: productForServiceID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ServiceSchemaStep[]>();
    this.httpService.get('/api/service-schema-step/r/get-steps-for-product', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public importFromProductionSchemaTemplate(productForServiceID: string, productionSchemaTemplateID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ServiceSchemaStep[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productForServiceID', value: productForServiceID});
  
    queryParamsList.push({name: 'productionSchemaTemplateID', value: productionSchemaTemplateID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ServiceSchemaStep[]>();
    this.httpService.post('/api/service-schema-step/w/import-from-production-schema', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ServiceSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ServiceSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ServiceSchemaStep>();
    this.httpService.put('/api/service-schema-step/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateServiceSchemaStepList(serviceSchemaStepList: ServiceSchemaStep[], jsonScope: JsonScope = new JsonScope(false, [])): Observable<ServiceSchemaStep[]>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ServiceSchemaStep[]>();
    this.httpService.put('/api/service-schema-step/w/update-service-schema-steps', JsonScopedSerializer.stringify(serviceSchemaStepList, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

