<ng-container
  [ngTemplateOutlet]="isMobileDevice ? mobileSubNavbar : displaySubFunctionalitiesUiForTerminalDevice() ? terminalSubFunctionalityNavbar : subNavbar"
></ng-container>

<ng-template #subNavbar>
  <div
    class="sub-navbar"
    *ngIf="!shouldNavbarBeHidden()"
    [ngClass]="isTerminalDevice ? 'sub-navbar--terminal-margin' : 'sub-navbar--desktop-margin'"
    data-cy="sub-navbar-div">
    <div
      class="sub-navbar__content sub-navbar--content-color"
      [ngClass]="isTerminalDevice ? 'global-margin--none' : ''">
      <div
        *ngIf="functionalities"
        class="sub-navbar__functionality-list-container">
        <div
          [ngClass]="[isTerminalDevice ? 'sub-navbar__functionalities-container--terminal' :'sub-navbar__functionalities-container--desktop',
      isActiveFunctionality(functionalityService.cutMainSubFunctionalityUrl(functionality.name)) ? 'active-functionality' : 'inactive-functionality']"
          *ngFor="let functionality of functionalities">
          <div
            *ngIf="!functionality.isDynamic; else dynamicFunctionalityComponent"
            (click)="redirectToCurrentSubFunctionality(functionality.name)">
          <span
            class="sub-navbar--functionality-name--color"
            [ngClass]="isTerminalDevice ? 'sub-navbar--functionality-name--terminal' :''">
            {{functionalityService.getUserFunctionalityName(functionality) | translate}}</span>
          </div>
          <ng-template #dynamicFunctionalityComponent>
            <div (click)="redirectToCurrentSubFunctionality(functionality.name)">
              <app-dynamic-functionality-container-component
                [componentNameToAttach]="functionality.name"
              ></app-dynamic-functionality-container-component>
            </div>
          </ng-template>
        </div>
      </div>


      <div
        #filteringButtons
        class="global-flex global-flex-direction__row global-justify__flex-end global-width--fit-content global-min-width--fit-content">
        <button
          class="button button__filter-button button__filter-button--background-color"
          type="button"
          data-cy="export-data-button"
          (click)="exportTableData()"
        >{{'common.export-data' | translate}}</button>
        <button
          class="button button__filter-button button__filter-button--background-color"
          type="button"
          data-cy="reset-filters-button"
          (click)=resetFilters()>
        <span
          class="global-flex global-justify__center">{{getButtonLabel() | translate}}</span>
        </button>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #mobileSubNavbar>
  <div *ngIf="isAnyFunctionalityGroupActive()">
    <div class="sub-functionality-menu" style="padding-bottom: 0.5rem">
      <p-panel
        header="Subfunctionalities"
        toggler="header"
        [ngClass]="'global-width--100'"
        [toggleable]="true"
        [(collapsed)]="collapsedFunctionalities">
        <div
          *ngFor="let functionality of functionalities"
          class="sub-functionality-menu__container">
          <div
            class="sub-functionality-menu__container__items"
            [ngClass]=" isActiveFunctionality(functionalityService.cutMainSubFunctionalityUrl(functionality.name)) ? 'active-functionality' : ''"
            (click)="redirectToCurrentSubFunctionality(functionality.name)">
            <div class="functionality">
              <span> {{functionalityService.getUserFunctionalityName(functionality) | translate}}</span>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</ng-template>

<ng-template #terminalSubFunctionalityNavbar>
  <div class="terminal-sub-navbar">
    <div class="terminal-sub-navbar__info">
      <span
        class="terminal-sub-navbar__title">{{('common.current-sub-functionality' | translate) + ': ' + ('common.' + getCurrentSubFunctionalityName() | translate)}}</span>
      <div class="terminal-sub-navbar__buttons-container">
        <button
          type="button"
          class="terminal-sub-navbar__export-button"
          (click)="exportTableData()"
        >{{'common.export-data' | translate}}</button>
        <button
          type="button"
          class="terminal-sub-navbar__reset-button"
          (click)=resetFilters()>
          {{getButtonLabel() | translate}}
        </button>
      </div>
    </div>

    <div *ngIf="isAnyFunctionalityGroupActive()"
    class="terminal-sub-navbar__panel">
      <p-panel
        header="{{'common.sub-functionalities'| translate}}"
        toggler="header"
        [ngClass]="'global-width--100'"
        [toggleable]="true"
        [(collapsed)]="collapsedFunctionalitiesForTerminalView">
        <div class="terminal-sub-navbar__functionalities">
            <div
              *ngFor="let functionality of functionalities"
              class="terminal-sub-navbar__functionality"
              [ngClass]=" isActiveFunctionality(functionalityService.cutMainSubFunctionalityUrl(functionality.name)) ? 'active-functionality' : ''"
              (click)="redirectToCurrentSubFunctionality(functionality.name)">
              <div>
                <span> {{functionalityService.getUserFunctionalityName(functionality) | translate}}</span>
              </div>
            </div>
          </div>
      </p-panel>
    </div>
  </div>
</ng-template>
