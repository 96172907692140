import {Inject, Injectable} from '@angular/core';
import {EnumTranslation, Language, StaticTranslation} from '../sdk/model-translations';
import {LanguageCtrl, StaticTranslationCtrl} from '../sdk/bighero-controllers';
import {Utility} from '../../shared/utilities/utility';
import {BehaviorSubject} from 'rxjs';
import {BaseEnumEntity} from '../sdk/model-enumentity';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  translationsLoaded: BehaviorSubject<string>;
  currentLanguage: Language;
  masterLanguage: Language;
  languages: Language[] = [];
  functionalityList: string[] = ['common', 'model'];
  translations: Map<string, Map<string, StaticTranslation>>;
  private translationsDownloaded = false;

  constructor(private languageCtrl: LanguageCtrl, @Inject('TranslationService') private staticTranslationCtrl: StaticTranslationCtrl) {
    this.translationsLoaded = new BehaviorSubject<string>('');
    this.translations = new Map<string, Map<string, StaticTranslation>>();
  }

  getCurrentLanguage(): Language {
    return this.currentLanguage ? this.currentLanguage : this.languages.find(value => value.locale === JSON.parse(localStorage.getItem('loggedPerson'))?.defaultLanguage);
  }

  getMasterLanguage(): Language {
    return this.masterLanguage ? this.masterLanguage : this.languages.find(value => value.isMaster);
  }

  loadLanguages(): void {
    this.languageCtrl.findAll()
      .subscribe((newLanguages: Language[]) => {
        this.languages = newLanguages;
        this.masterLanguage = newLanguages.find(value => value.locale === this.getMasterLanguage().locale) as Language;
        const browserLanguage = newLanguages.find(value => {
          if (value.locale) {
            return Utility.getLocaleId(value.locale) === Utility.getLocaleId(navigator.language);
          }
        });
        this.currentLanguage = this.getCurrentLanguage() ? this.getCurrentLanguage() : browserLanguage ?
          browserLanguage : this.getMasterLanguage();


        for (const lang of newLanguages) {
          if (lang.locale) {
            if (!this.translations[lang.locale]) {
              this.translations[lang.locale] = {};
            }
          }
        }
        this.functionalityList.forEach(functionality => {
          this.loadTranslations(functionality);
        });
      });

  }

  public loadTranslationModule(newModule: string): void {
    if (!this.currentLanguage) {
      this.currentLanguage = this.getMasterLanguage();
    }
    if (!this.currentLanguage) {
      setTimeout(() => this.loadTranslationModule(newModule));
      return;
    }
    if (this.currentLanguage.locale && !this.translations[this.currentLanguage.locale]) {
      this.loadTranslations('common');
      this.loadTranslations('model');
    }
    if (this.currentLanguage.locale && !this.translations[this.currentLanguage.locale][newModule]) {
      this.loadTranslations(newModule);
    }
  }

  private loadTranslations(functionality: string): void {
    if (this.currentLanguage && this.currentLanguage.locale && !this.translations[this.currentLanguage.locale][functionality]) {
      this.staticTranslationCtrl.getStaticTranslations(this.currentLanguage.locale, functionality)
        .subscribe(staticTranslations => {
          const functionalityTranslations = {};
          for (const staticTranslation of staticTranslations) {
            if (staticTranslation.uiKey) {
              functionalityTranslations[staticTranslation.uiKey] = staticTranslation;
            }
          }
          if (this.currentLanguage.locale) {
            this.translations[this.currentLanguage.locale][functionality] = functionalityTranslations;
          }
          this.translationsLoaded.next(functionality);
        });
    }
  }

  setLanguage(newLanguage: Language, currentFunctionalityName?: string): void {
    this.currentLanguage = newLanguage;
    if (newLanguage.locale && this.currentLanguage.locale) {
      if (!this.translations[newLanguage.locale].common) {
        this.loadTranslations('common');
      }
      if (this.translations[this.currentLanguage.locale]) {
        if (!this.translations[this.currentLanguage.locale].model) {
          this.loadTranslations('model');
        }
        if (currentFunctionalityName) {
          if (!this.translations[this.currentLanguage.locale][currentFunctionalityName]) {
            this.loadTranslations(currentFunctionalityName);
          }
        }
      }
    }
  }

  translate(key: string): string {
    if (!this.currentLanguage && !this.translationsDownloaded) {
      this.loadLanguages();
      this.translationsDownloaded = true;
    }
    const translationKeyComponents = key.split('.');
    if (translationKeyComponents.length < 2) {
      return key;
    }
    const [functionality, ...uiKeyList] = translationKeyComponents;
    const uiKey = uiKeyList.join('.');

    if (this.currentLanguage === undefined ||
      !(this.translations[this.currentLanguage.locale][functionality]) && this.currentLanguage.locale) {
      return key;
    }

    if (this.currentLanguage.locale && this.translations[this.currentLanguage.locale][functionality][uiKey]) {
      return this.translations[this.currentLanguage.locale][functionality][uiKey].name;
    }
    // return key when not found
    return key;
  }

  translateEnum(key: string): string {
    const finalKey = key.split('.');
    finalKey[1] = finalKey[1].split('_').join('-').toLowerCase();
    return (this.translate(finalKey.join('.')));
  }

  isTranslationKeyPresent(key: string): boolean {
    return this.translate(key) !== key;
  }

  public getNewEnumTranslation(language: Language, value: string, enumEntity?: BaseEnumEntity): EnumTranslation {
    return {
      id: 'enum-translation/' + Utility.getUUID(),
      version: 0,
      timestamp: Date.now(),
      language,
      translationValue: value,
      enumEntity
    };
  }
}
