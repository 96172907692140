import {Directive, ElementRef, HostListener, Input, OnDestroy} from '@angular/core';
import {VirtualKeyboardService} from '../services/virtual-keyboard/virtual-keyboard.service';
import Keyboard from 'simple-keyboard';
import {AbstractControl, FormGroupDirective} from '@angular/forms';
import germanKeyboardLayout from 'simple-keyboard-layouts/build/layouts/german';
import englishKeyboardLayout from 'simple-keyboard-layouts/build/layouts/english';
import {TranslateService} from '../../core/translations/translate.service';
import {LayoutItem} from 'simple-keyboard-layouts/build/interfaces';
import {Utility} from '../utilities/utility';
import {BiDirectionalService} from '../services/bi-directional-service/bi-directional.service';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appVirtualKeyboard]'
})
export class VirtualKeyboardDirective implements OnDestroy {
  @Input() public formGroupDirective: FormGroupDirective;
  @Input() public control: AbstractControl;
  public useVirtualKeyboard = true;
  public keyboard: Keyboard;
  public readonly spaceFromTargetElement = 15;

  private subscription: Subscription;

  constructor(private elementRef: ElementRef,
              private virtualKeyboardService: VirtualKeyboardService,
              private translateService: TranslateService,
              private bidirectionalService: BiDirectionalService) {
    this.subscription = this.bidirectionalService.getSubscriptionFromMap('useVirtualKeyboard')
      .subscribe(data => this.useVirtualKeyboard = data);
  }

  @HostListener('focusin') focusIn(): void {
    if (Utility.isTerminalDevice() && this.useVirtualKeyboard) {
      setTimeout(() => {
        this.createKeyboard();
        const positionTop = this.getElementTopPosition() + window.pageYOffset;
        this.virtualKeyboardService.handleVirtualKeyboardAction(true, (positionTop + this.spaceFromTargetElement));
      }, 200);
    }
  }

  @HostListener('blur') focusOut(): void {
    if (Utility.isTerminalDevice() && this.useVirtualKeyboard) {
      this.finishVirtualKeyboard();
    }
  }

  public createKeyboard(): void {
    this.keyboard = new Keyboard({
      onChange: inputValue => this.onChange(inputValue),
      onKeyPress: (button: string) => this.onKeyPress(button),
      ...this.getProperKeyboardLayoutBasedOnCurrentLanguage(),
      inputPattern: this.isInputElementModeDecimal() ? this.getDoubleInputValidationPattern() :
        this.isInputElementTypeNumber() ? this.getIntegerInputValidationPattern() : ''
    });
    this.elementRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
    this.keyboard.setInput(this.elementRef.nativeElement.value);
  }

  private getDoubleInputValidationPattern(): RegExp {
    return /^[0-9,]*\.?[0-9,]*$/;
  }

  private getIntegerInputValidationPattern(): RegExp {
    return /[0-9]*$/;
  }

  private isInputElementModeDecimal(): boolean {
    return this.elementRef.nativeElement.inputMode === 'decimal';
  }

  private isInputElementTypeNumber(): boolean {
    return this.elementRef.nativeElement.type === 'number';
  }

  private getProperKeyboardLayoutBasedOnCurrentLanguage(): LayoutItem {
    return (this.isInputElementTypeNumber() || this.isInputElementModeDecimal()) ?
      this.getNumericLayoutItem() : this.translateService.getCurrentLanguage().locale.includes('en') ?
        englishKeyboardLayout : germanKeyboardLayout;
  }

  private getNumericLayoutItem(): LayoutItem {
    return {
      layout: {
        default: [
          '0 1 2 3',
          '4 5 6 7',
          '8 9 . {bksp}',
          '{enter}'],
      }
    };
  }

  private getElementTopPosition(): any {
    const elementTopPosition = this.elementRef.nativeElement.getBoundingClientRect();
    return elementTopPosition.top + elementTopPosition.height;
  }

  public onChange(inputValue: string): void {
    if (this.control) {
      this.control.setValue(inputValue);
    } else {
      this.elementRef.nativeElement.value = inputValue;
    }
  }

  public onKeyPress(button: string): void {
    if (button === '{shift}' || button === '{lock}') {
      this.handleShiftClickAction();
    }
    if (button === '{enter}') {
      this.handleEnterClickAction();
    }
  }

  private handleEnterClickAction(): void {
    if (this.formGroupDirective && this.formGroupDirective.form.valid) {
      this.formGroupDirective.ngSubmit.emit();
    } else {
      this.elementRef.nativeElement.dispatchEvent(new KeyboardEvent('keydown', {key: 'enter'}));
    }
    this.elementRef.nativeElement.blur();
  }

  private handleShiftClickAction(): void {
    const currentLayout = this.keyboard.options.layoutName;
    const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';
    this.keyboard.setOptions({layoutName: shiftToggle});
  }

  private finishVirtualKeyboard(): void {
    setTimeout(() => {
      this.keyboard.destroy();
      this.virtualKeyboardService.handleVirtualKeyboardAction(false, null);
    }, 200);
  }

  public ngOnDestroy(): void {
    if (this.keyboard) {
      this.finishVirtualKeyboard();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
