import {Injectable} from '@angular/core';
import {Utility} from '../utilities/utility';
import {AdditionalAddressLine, Address} from '../../core/sdk/model-address';
import {AddressType} from '../../core/sdk/enums-types';
import {Observable, of} from 'rxjs';
import {Customer} from '../../core/sdk/bighero-model';
import {AdditionalAddressLineCtrl, AddressCtrl} from '../../core/sdk/controllers-address';
import {JsonScope} from '../../core/sdk/jsonScope';
import {catchError, switchMap} from 'rxjs/operators';
import {NotificationService} from './notification.service';
import {NotificationMessageType} from '../enums/NotificationMessageType';
import {AdditionalAddressLineType} from '../../core/sdk/model-enumentity';
import {AdditionalAddressLineTypeCtrl} from '../../core/sdk/controllers-enumentity';

@Injectable({
  providedIn: 'root'
})
export class AddressService {


  constructor(private addressCtrl: AddressCtrl,
              private additionalAddressLineCtrl: AdditionalAddressLineCtrl,
              private additionalAddressLineTypeCtrl: AdditionalAddressLineTypeCtrl,
              private notificationService: NotificationService) {
  }

/// ADDRESS

  getNewAddress(customer?: Customer, addressType?: AddressType): Address {
    return {
      customer,
      id: 'address/' + Utility.getUUID(),
      timestamp: Date.now(),
      version: 0,
      additionalAddressLineList: [],
      addressType,
      buildingNumber: null,
      country: null,
      city: null,
      flatNumber: null,
      postalCode: null,
      street: null
    };
  }


  createAddress(address: Address): Observable<Address> {
    return this.addressCtrl.createObject(address, new JsonScope(false, ['customer']));
  }

  updateAddress(address: Address): Observable<Address> {
    return this.addressCtrl.updateAddress(address, new JsonScope(false, ['customer']));
  }

  removeAddress(addressId: string): Observable<void> {
    return this.addressCtrl.deleteObject(Utility.getObjectId(addressId));
  }

  countCustomerAddressList(customerId: string): Observable<number> {
    return this.addressCtrl.getCustomerAddressListCount(Utility.getObjectId(customerId));
  }

  getCustomerAddressList(customerId: string, pageNumber: number, pageSize: number): Observable<Address[]> {
    return this.addressCtrl.getCustomerAddressList(Utility.getObjectId(customerId), pageNumber, pageSize);
  }

  countCustomerDeliveryAddressList(customerId: string): Observable<number> {
    return this.addressCtrl.getCustomerDeliveryAddressListCount(Utility.getObjectId(customerId));
  }

  getCustomerDeliveryAddressList(customerId, pageNumber: number, pageSize: number): Observable<Address[]> {
    return this.addressCtrl.getCustomerDeliveryAddressList(Utility.getObjectId(customerId), pageNumber, pageSize);
  }


  /// ADDITIONAL_ADDRESS_LINE
  getNewAdditionalAddressLine(address?: Address, type?: AdditionalAddressLineType, value?: string): AdditionalAddressLine {
    return {
      id: 'additional-address-line/' + Utility.getUUID(),
      timestamp: Date.now(),
      version: 0,
      address,
      type,
      value
    };
  }

  getAdditionalAddressLineType(): Observable<AdditionalAddressLineType[]> {
    return this.additionalAddressLineTypeCtrl.findAllEnumObjects();
  }

  createAdditionalAddressLineType(additionalAddressLineType: string): Observable<AdditionalAddressLineType> {
    return this.additionalAddressLineTypeCtrl.createObjectByName(additionalAddressLineType);
  }

  removeAdditionalAddressLine(addressLineId: string): Observable<void> {
    return this.additionalAddressLineCtrl.deleteObject(Utility.getObjectId(addressLineId));
  }

  updateAdditionalAddressLineList(lineList: AdditionalAddressLine[]): Observable<AdditionalAddressLine[]> {
    return this.additionalAddressLineCtrl.updateAdditionalAddressLinesFromList(lineList, new JsonScope(false, ['address']));
  }


  public createAddressAndAdditionalLines(address: Address): Observable<AdditionalAddressLine[]> {
    const additionalLinesForExtraAddress = [...address.additionalAddressLineList];
    address.additionalAddressLineList = null;
    return this.createAddress(address).pipe(
      switchMap(() => {
        return this.updateAdditionalAddressLineList(additionalLinesForExtraAddress)
          .pipe(catchError((error) => {
              this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
              return of(null);
            })
          );
      }),

      catchError((error) => {
        this.notificationService.displayNotificationToast(
          Utility.getErrorMessageFromResponse(error),
          NotificationMessageType.ERROR
        );
        return of(null);
      })
    );
  }

}
